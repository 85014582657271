import { ThemeProvider } from "styled-components"
import { createContext, FC, useContext } from "react"
import { GlobalStyle } from "shared/styles/GlobalStyle"
import { theme as themeDefault } from "shared/styles/theme"
import { useLocalStorage } from "@uidotdev/usehooks";

interface IProviders {
    children: JSX.Element
}

const ThemeContext = createContext({
    theme: themeDefault,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setThemeColor: (color: string) => {},
});

export const useThemeContext = () => useContext(ThemeContext);

export const Providers: FC<IProviders> = ({ children }) => {

    const [theme, setTheme] = useLocalStorage("appTheme", themeDefault);

    const setThemeColor = (color: string) => {
        setTheme((prevTheme) => ({
            ...prevTheme,
            themeColors: {
                main: color,
            },
        }));
    };

    return (
        <ThemeContext.Provider value={{ theme, setThemeColor }}>
            <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}