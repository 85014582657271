import React from 'react'
import { Icon } from '../Icons'
import { StyledButton } from './style'
import { theme } from 'shared/styles/theme'
import { useLocalStorage } from '@uidotdev/usehooks'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode
    themeType?: 'primary' | 'outline' | 'item' | 'outline-2' | 'delete'
    beforeIcon?: string
    afterIcon?: string
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
    loading?: boolean
    bgLoading?: string
    padding?: number
    fullWidth?: boolean
    hidden?: boolean
    requiredPermission?: 'edit' | 'save' | 'delete' | 'add';
}

export const Button: React.FC<ButtonProps> = ({
    children,
    themeType = 'primary',
    padding,
    beforeIcon,
    afterIcon,
    loading = false,
    bgLoading = theme.themeColors.main,
    fullWidth,
    hidden = false,
    requiredPermission,
    ...props
}) => {

    const [permissions] = useLocalStorage<{ edit_permissions?: { name: string }[] }>(
        'permissionsUsers',
        {}
    );

    const isEditAccessDenied = permissions?.edit_permissions?.some(
        (perm) => perm.name === 'is_edit_access'
    );

    if (!isEditAccessDenied && ['edit', 'save', 'delete', 'add'].includes(requiredPermission || '')) {
        return null;
    }

    return !hidden ? (
        <StyledButton
            className='styledButton'
            themeType={themeType}
            padding={padding}
            disabled={props?.disabled}
            fullWidth={fullWidth}
            {...props}
        >
            {beforeIcon && <Icon name={beforeIcon} />}
            {loading ? (
                <div className='loader' style={{ background: bgLoading }}></div>
            ) : (
                <span style={{ textAlign: 'center' }}>{children}</span>
            )}
            {afterIcon && <Icon name={afterIcon} />}
        </StyledButton>
    ) : null;
}
