import styled from 'styled-components'

export const StyledAddedNomenclatureContainer = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
`

export const StyledAddedNomenclature = styled.div`
    padding: 4px 8px 4px 10px;
    border: 1px solid #e1e4ea;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    width: fit-content;
    gap: 4px;
`

export const StyledContainer = styled.div`
    flex-direction: column;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`

export const StyledNomenclatureInputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
`

export const StyledAutocompleteWrapper = styled.div`
    flex: 1;
`

export const StyledNomenclatureDiscountInputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 10px;
`

export const StyledCrossIconContainer = styled.div`
    background: #8181a5;
    cursor: pointer;
    height: 15px;
    min-height: 15px;
    width: 15px;
    min-width: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NomenclatureLabelContainer = styled.span`
    color: #282a42;
`

export const NomenclatureDiscountLabel = styled.span`
    color: #8181a5;
`

export const NomenclatureOldPriceLabel = styled.span`
    color: #8181a5;
    text-decoration: line-through;
`
