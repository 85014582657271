import { FC } from 'react'
import { NomenclatureOldPriceLabel } from 'entities/bookings/ui/styles'
import { StyledDescriptionItem } from './styles'

interface AreaListPreviewItemProps {
    areaName: string
    range: string
    areaPrice: number
    areaDiscount: number
}

export const AreaListPreviewItem: FC<AreaListPreviewItemProps> = ({
    areaName,
    range,
    areaPrice,
    areaDiscount,
}) => (
    <StyledDescriptionItem>
        {areaName}: {range} (
        {areaPrice && areaDiscount > 0 ? (
            <>
                <NomenclatureOldPriceLabel>
                    ₽{areaPrice}
                </NomenclatureOldPriceLabel>{' '}
                ₽{areaPrice - areaDiscount}
            </>
        ) : (
            <>₽{areaPrice}</>
        )}
        ₽/час)
    </StyledDescriptionItem>
)
