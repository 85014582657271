import { DashboardLayout } from "app/layout"
import { NomenclatureCreate } from "features/Nomenclature/ui/NomenclatureCreate"
import { useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { BackButton } from "shared/components/BackButton"
import { Button } from "shared/components/Button"
import { NOMENCLATURELIST } from "shared/consts/routingConsts"
import { openModal, closeModal } from "shared/redux/slice/modalSlice"
import { StyledItem } from "./style"
import { FormikHelpers } from "formik"

export const NomenclatureCreatePages = () => {
    const formikRef = useRef<FormikHelpers<any>>(null);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState(0);
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleClick = (to: string) => {
        navigate(to);
    }

    const handleSaveClick = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const handleCancelCreate = () => {
        dispatch(closeModal());
        navigate(NOMENCLATURELIST);
    }

    const onDisabled = (disabled: boolean) => {
        setDisabled(disabled)
    }

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <StyledItem>
                    <BackButton
                        text="Назад"
                        onClick={() => handleClick(NOMENCLATURELIST)}
                    />
                    <div className="dashboard__header-title">Новое оборудование</div>
                </StyledItem>
                <div className="dashboard__right-menu">
                    <Button requiredPermission="save" bgLoading="white" loading={loading} onClick={handleSaveClick} disabled={disabled}>
                        Сохранить
                    </Button>
                    <Button 
                        requiredPermission="delete"
                        themeType="outline-2" 
                        onClick={() => dispatch(openModal({
                            isOpen: true,
                            content: (
                                <>Вы точно хотите отменить создание?</>
                            ),
                            config: {
                                title: "Вы точно хотите отменить?",
                                btns: [
                                    {
                                        onClick: () => handleCancelCreate(),
                                        title: "Да",
                                        active: true
                                    },
                                    {
                                        onClick: () => dispatch(closeModal()),
                                        title: "Нет",
                                        active: false
                                    }
                                ]
                            }
                        }))}
                    >Отменить</Button>
                </div>
            </div>
        }>
            <NomenclatureCreate
                formikRef={formikRef}
                setActiveTab={setActiveTab}
                setDisabled={onDisabled}
                setLoading={setLoading}
                activeTab={activeTab}
            />
        </DashboardLayout>
    )
}