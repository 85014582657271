import styled from 'styled-components'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'

export const StyledCreatableSelect = styled(CreatableSelect)`
    .control {
        padding: 1px;
        border-radius: 8px;
        border: 1px solid #e1e4ea;
        max-height: 40px;
        box-shadow: none;
        &:hover {
            border: 1px solid hsl(0, 0%, 70%);
        }
    }
    .input {
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        color: #282a42;
    }
    .placeholder {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #CDCDDF;
    }
`

export const StyledSelect = styled(Select)`
    .control {
        padding: 1px;
        border-radius: 8px;
        border: 1px solid #e1e4ea;
        max-height: 40px;
        box-shadow: none;
        &:hover {
            border: 1px solid hsl(0, 0%, 70%);
        }
    }
    .input {
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        color: #282a42;
    }
    .placeholder {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #CDCDDF;
    }
`
