import { configureStore } from '@reduxjs/toolkit'
import sidebarReducer from './slice/sidebar'
import modalReducer from './slice/modalSlice'
import notificationReducer from './slice/notificationSlice'

import authReducer from 'features/auth/model/authSlice'
import bookingSidebarForm from '../../features/Booking/model/slices/bookingSidebarForm'
import { createSelector } from 'reselect';

export const store = configureStore({
    reducer: {
        sidebar: sidebarReducer,
        auth: authReducer,
        modal: modalReducer,
        notification: notificationReducer,
        bookingSidebarForm,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const selectModalState = (state: RootState) => state.modal;

export const isModal = createSelector(
    [selectModalState],
    (modal) => ({
        isOpen: modal.isOpen,
        isFullScreen: modal.isFullScreen,
        isContent: modal.content,
        isConfig: modal.config,
    })
);

const selectNotificationState = (state: RootState) => state.notification;

export const isNotification = createSelector(
    [selectNotificationState],
    (notification) => ({
        isMessage: notification.message,
        isType: notification.type,
        ErrMessage: notification.errMessage,
    })
);