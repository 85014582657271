import { SidebarMenuTypes } from "shared/types/sidebarMenuTypes";
import { CALENDAR, CLIENTSLIST, MANAGEMENT, SETTING, SETTINGBOOKINGS, SETTINGNOTIFICATION, SETTINGORG, SETTINGPROFILE, SETTINGROLE, SETTINGTHEME } from "./routingConsts";

export const sidebarMenuData: SidebarMenuTypes[] = [
    {
        icon: "home",
        link: "/",
        id: 20041,
    },
    {
        icon: "calendar",
        link: CALENDAR,
        id: 20042
    },
    {
        icon: "users",
        link: CLIENTSLIST,
        id: 20043,
    },
    {
        icon: "fourblock",
        link: MANAGEMENT,
        id: 20044,
    },
    {
        icon: "setting",
        link: SETTING,
        id: 20047
    }
];

export const sidebardMenuSetting = [
    {
        icon: "settingProfile",
        title: "Профиль",
        link: SETTINGPROFILE,
    },
    {
        icon: "settingTheme",
        title: "Тема",
        link: SETTINGTHEME
    },
    {
        icon: "settingNotification",
        title: "Уведомления",
        link: SETTINGNOTIFICATION
    },
    {
        icon: "settingBookings",
        title: "Оплата и бронирование",
        link: SETTINGBOOKINGS
    },
    {
        icon: "settingRole",
        title: "Роли",
        link: SETTINGROLE
    },
    {
        icon: "settingOrg",
        title: "Организации",
        link: SETTINGORG
    }
];