import { axiosApi } from "shared/api/axios";

const AuthService = {
    login(login: string, password: string) {
        return axiosApi.post("/api/oauth/v1/auth/login", { login, password });
    },
    auth() {
        return axiosApi.get("/api/v1/users/user/info/me");
    },
    refreshToken() {
        return axiosApi.post("/api/oauth/v1/auth/refresh_token");
    },
    
    logout() {
        return axiosApi.post("/api/oauth/v1/auth/logout");
    }
};

export default AuthService;