import { axiosApi } from 'shared/api/axios'
import { AreasEndpoint } from './constants'
import { GetAreasResponseDTO } from '../model/dtoTypes'
import { Area } from '../model/types'
import { adaptAreaDTO } from '../model/adapters/adaptAreaDTO'

export const getAreas = async (): Promise<Area[]> => {
    try {
        const response = await axiosApi.get<GetAreasResponseDTO>(
            AreasEndpoint.area,
            {
                params: {
                    limit: 100,
                },
            },
        )
        return response.data.areas.map(adaptAreaDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка')
    }
}
