import React, { useEffect, useState } from 'react'
import { AccordionWrapper } from './style'

interface IPropsAccordionCustom {
    title?: string
    isOpenByDefault?: boolean
    isOpen?: boolean
    children: React.ReactNode
    disabled?: boolean
    handleOpen?: (isOpen: boolean) => void
    isSecondaryTitle?: boolean
    className?: string;
}

export const Accordion = ({
    title,
    children,
    isOpen,
    isOpenByDefault = false,
    disabled,
    handleOpen,
    isSecondaryTitle,
    className
}: IPropsAccordionCustom) => {
    const [isOpenState, setIsOpenState] = useState(isOpenByDefault)

    const handleClose = () => {
        if (disabled) {
            return
        }
        if (handleOpen) {
            handleOpen(!isOpenState)
        }
        setIsOpenState(prev => !prev)
    }

    useEffect(() => {
        if (isOpen !== null && isOpen !== undefined) {
            setIsOpenState(isOpen)
        }
    }, [isOpen])

    const isOpenStateAndProp = isOpen || isOpenState
    return (
        <AccordionWrapper className={className}>
            <div className='header' onClick={handleClose}>
                <div
                    className='title'
                    style={{ color: isSecondaryTitle ? '#8181A5' : '#282a42' }}
                >
                    {title}
                </div>
                <div className='toggle-arrow'>
                    <div
                        className='toggle-arrow-item'
                        style={{
                            transform: isOpenStateAndProp
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                        }}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='18'
                            viewBox='0 0 24 18'
                            fill='none'
                        >
                            <path
                                d='M17 6.49951L12 11.4995L7 6.49951'
                                stroke='#363853'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                        </svg>
                    </div>
                </div>
            </div>
            {isOpenStateAndProp && children}
        </AccordionWrapper>
    )
}
