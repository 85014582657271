import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OrdersApi } from 'entities/orders/api'
import { OrderBooking } from 'entities/orders/model'
import { BookingsApi } from 'entities/bookings/api'
import { OrderNomenclatureToAdd } from 'entities/bookings/ui'
import { Nomenclature as OrderNomenclature } from 'entities/orders/model'

import { showNotification } from 'shared/redux/slice/notificationSlice'

import {
    ActiveBookingFormTab,
    addEmptyBooking,
    BookingMode,
    BookingStep,
    removeBooking,
    replaceBookingTempClientIdToOriginId,
    selectActiveBookingFormTab,
    selectActiveBookingId,
    selectBookingMode,
    selectBookingOrderId,
    selectBookings,
    setActiveBookingId,
    setBookingActiveTab,
    setBookingCurrentStep,
} from '../slices'
import { useIsBookingsAlreadyCreated } from './useIsBookingAlreadyCreatedHelper'
import { useFillStore } from './useFillStore'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'

export const useBookingFormController = () => {
    const dispatch = useDispatch()
    const orderId = useSelector(selectBookingOrderId)
    const updateBookings = useFillStore()
    const [isLoading, setIsLoading] = useState(false)

    const mode = useSelector(selectBookingMode)
    const isCreate = useMemo(() => mode === BookingMode.Create, [mode])

    const {
        checkIsBookingAlreadyCreated,
        isActiveBookingAlreadyCreated,
        refetchAlreadyCreatedBookings,
    } = useIsBookingsAlreadyCreated()

    const activeBookingId = useSelector(selectActiveBookingId)
    const bookings = useSelector(selectBookings)
    const activeBooking = useMemo(
        () => bookings.find(a => a.id === activeBookingId),
        [bookings, activeBookingId],
    )

    const activeTab = useSelector(selectActiveBookingFormTab)
    const setActiveTab = (newActiveTab: ActiveBookingFormTab) => {
        dispatch(setBookingActiveTab({ tab: newActiveTab }))
    }

    const isCreateDisabledTab =
        activeTab === ActiveBookingFormTab.Booking
            ? checkIsBookingAlreadyCreated(activeBooking)
                ? undefined
                : ActiveBookingFormTab.Nomenclatures
            : undefined

    const isEditDisabledTab = isActiveBookingAlreadyCreated
        ? undefined
        : isCreateDisabledTab

    const disabledTab = isCreate ? isCreateDisabledTab : isEditDisabledTab

    const membersCount = useMemo(
        () => activeBooking?.membersCount ?? 0,
        [activeBooking],
    )

    const isButtonDisabled =
        activeTab === ActiveBookingFormTab.Booking &&
        (!orderId ||
            bookings.some(
                booking =>
                    !booking.area?.id ||
                    !booking?.startTime ||
                    !booking?.endTime,
            ))

    const onAddBooking = () => {
        const bookingId = new Date().getMilliseconds() ** 2
        dispatch(addEmptyBooking({ bookingId }))
        dispatch(setActiveBookingId({ bookingId }))
        setActiveTab(ActiveBookingFormTab.Booking)
    }

    const onRemoveBooking = async (bookingId: number) => {
        if (!orderId) {
            return
        }

        const deleteBooking = async () => {
            if (isCreate) {
                if (activeBookingId === bookingId) {
                    dispatch(setActiveBookingId({ bookingId: null }))
                }
                return dispatch(removeBooking({ bookingId }))
            }

            dispatch(removeBooking({ bookingId }))
            await OrdersApi.deleteBookingFromOrder({
                orderId,
                bookingId,
            })
            await refetchAlreadyCreatedBookings()
            await updateBookings(
                orderId,
                activeBookingId !== bookingId ? activeBookingId : undefined,
            )
        }

        const content = 'Вы уверены, что хотите удалить это бронирование?'
        const title = 'Вы точно хотите удалить это бронирование?'
        const btns = [
            {
                onClick: () => {
                    deleteBooking().then()
                    dispatch(closeModal())
                },
                title: 'Да',
                active: true,
            },
            {
                onClick: () => dispatch(closeModal()),
                title: 'Нет',
                active: false,
            },
        ]

        dispatch(
            openModal({
                isOpen: true,
                content,
                config: {
                    title,
                    btns,
                },
            }),
        )
    }

    const onChangeActiveBookingId = (bookingId: number) => {
        dispatch(setActiveBookingId({ bookingId }))
    }

    const showSuccessAlert = () => {
        dispatch(
            showNotification({
                message: 'Успешно',
                type: 'success',
            }),
        )
    }

    const showErrorAlert = () => {
        dispatch(
            showNotification({
                message: 'Ошибка',
                type: 'error',
            }),
        )
    }

    const onAddNomenclature = async (nomenclature: OrderNomenclatureToAdd) => {
        const nomenclatureId = nomenclature.id
        const count = nomenclature.count
        const absolutDiscount = nomenclature.absolutDiscount
        const percentDiscount = nomenclature.percentDiscount

        if (orderId && activeBookingId && nomenclatureId) {
            setIsLoading(true)
            try {
                const existingNomenclature = activeBooking?.nomenclatures.find(
                    nomenclature =>
                        nomenclature.id === nomenclatureId &&
                        (nomenclature?.absolutDiscount ?? 0).toString() ===
                            (absolutDiscount ?? 0).toString() &&
                        (nomenclature?.percentDiscount ?? 0).toString() ===
                            (percentDiscount ?? 0).toString(),
                )

                if (existingNomenclature && orderId) {
                    await BookingsApi.updateBookingNomenclature(
                        {
                            bookingNomenclatureId:
                                existingNomenclature.bookingNomenclatureId,
                            orderId,
                            bookingId: activeBookingId,
                        },
                        {
                            count: existingNomenclature.count + 1,
                        },
                    )
                } else {
                    await BookingsApi.addNomenclaturesToBooking({
                        bookingId: activeBookingId,
                        nomenclatureId: nomenclatureId,
                        count,
                        absolutDiscount:
                            absolutDiscount > 0 ? absolutDiscount : undefined,
                        percentDiscount:
                            percentDiscount > 0 ? percentDiscount : undefined,
                    })
                }

                await updateBookings(orderId, undefined, true)
                showSuccessAlert()
            } catch (e) {
                console.log(e)

                showErrorAlert()
            } finally {
                setIsLoading(false)
            }
        }
    }

    const onRemoveNomenclature = async (nomenclature: OrderNomenclature) => {
        const bookingNomenclatureId = nomenclature.id

        if (orderId && activeBookingId && bookingNomenclatureId) {
            setIsLoading(true)
            try {
                await BookingsApi.deleteNomenclaturesFromBooking({
                    orderId,
                    bookingId: activeBookingId,
                    bookingNomenclatureId,
                })
                await updateBookings(orderId, activeBookingId)
                showSuccessAlert()
            } catch (e) {
                console.log(e)

                showErrorAlert()
            } finally {
                setIsLoading(false)
            }
        }
    }

    const onGoNext = async () => {
        if (!orderId) {
            return
        }

        if (activeTab === ActiveBookingFormTab.Booking) {
            setIsLoading(true)
            try {
                await Promise.all(
                    bookings.map(async booking => {
                        const alreadyCreated =
                            checkIsBookingAlreadyCreated(booking)

                        if (alreadyCreated) {
                            return onUpdateBooking(booking)
                        }
                        return onCreateBooking(booking)
                    }),
                )

                setActiveTab(ActiveBookingFormTab.Nomenclatures)
                await refetchAlreadyCreatedBookings()
                await updateBookings(orderId, undefined, true)
                showSuccessAlert()
            } catch (e) {
                console.log(e)
                showErrorAlert()
            } finally {
                setIsLoading(false)
            }
            return
        }

        dispatch(setBookingCurrentStep({ step: BookingStep.Payment }))
    }

    const onCreateBooking = async (booking: OrderBooking) => {
        if (
            !booking.area?.id ||
            !orderId ||
            !booking?.startTime ||
            !booking?.endTime
        ) {
            return
        }

        const newBooking = {
            orderId,
            areaId: booking.area.id,
            startTime: new Date(
                booking.startTime.setSeconds(0, 0),
            ).toISOString(),
            endTime: new Date(booking.endTime.setSeconds(0, 0)).toISOString(),
            areaPercentDiscount:
                booking?.areaPercentDiscount && booking.areaPercentDiscount > 0
                    ? booking.areaPercentDiscount
                    : undefined,
            areaAbsolutDiscount:
                booking?.areaAbsolutDiscount && booking.areaAbsolutDiscount > 0
                    ? booking.areaAbsolutDiscount
                    : undefined,
            membersCount:
                booking?.membersCount && booking.membersCount > 0
                    ? booking.membersCount
                    : undefined,
            comment: booking.comment ?? '',
        }
        const { bookingId } = await OrdersApi.addBookingToOrder(newBooking)
        dispatch(
            replaceBookingTempClientIdToOriginId({
                oldId: booking.id,
                newId: bookingId,
            }),
        )
        if (booking.id === activeBookingId) {
            dispatch(
                setActiveBookingId({
                    bookingId,
                }),
            )
        }
    }

    const onUpdateBooking = async (booking: OrderBooking) => {
        if (
            !booking.area?.id ||
            !orderId ||
            !booking?.startTime ||
            !booking?.endTime
        ) {
            return
        }

        const queries = {
            orderId,
            bookingId: booking.id,
        }

        const updatedBooking = {
            areaId: booking.area.id,
            startTime: booking.startTime,
            endTime: booking.endTime,
            areaPercentDiscount:
                booking?.areaPercentDiscount && booking.areaPercentDiscount > 0
                    ? booking.areaPercentDiscount
                    : undefined,
            areaAbsolutDiscount:
                booking?.areaAbsolutDiscount && booking.areaAbsolutDiscount > 0
                    ? booking.areaAbsolutDiscount
                    : undefined,
            membersCount,
            comment: booking.comment ?? '',
        }
        await BookingsApi.updateBookings(queries, updatedBooking)
    }

    return {
        isLoading,
        activeBooking,
        activeBookingId,
        bookings,
        activeTab,
        disabledTab,
        isButtonDisabled,

        setActiveTab,
        onAddNomenclature,
        onRemoveNomenclature,

        onChangeActiveBookingId,
        onAddBooking,
        onRemoveBooking,

        onGoNext,
    }
}
