import moment from 'moment'

import { DashboardLayout } from 'app/layout'
import { ReactBigCalendar } from 'features/ReactBigCalendar'
import {
    useCalendarController,
    ViewMode,
} from 'features/BookingCalendarController/model'
import { Button } from 'shared/components/Button'
import { TabLink } from 'shared/components/TabLink'
import { linksCalendar } from 'shared/consts/linksCalendar'
import { PeriodSelector } from 'shared/components/PeriodSelector'
import { StyledCalendarHeaderContainer } from 'pages/calendarDay/style'

export const CalendarWeekPage = () => {
    const {
        currentDate,
        bookingEvents,

        onKeyPress,
        onSelectSlot,
        onDropSlot,
        onClickEvent,
        onEventResize,

        handleDateChange,
        onNewBookingClick,
    } = useCalendarController({ view: ViewMode.Week })

    const DayColumnWrapper = (e: any) => {
        return (
            <div>
                {moment(e.date).format('DD')} - {moment(e.date).format('dd')}
            </div>
        )
    }

    return (
        <DashboardLayout
            header={
                <StyledCalendarHeaderContainer>
                    <TabLink
                        className='calendar-tabs-header'
                        list={linksCalendar}
                    />
                    <PeriodSelector period='week' />
                    <div className='dashboard__right-menu'>
                        <Button onClick={onNewBookingClick}>
                            + Новая бронь
                        </Button>
                    </div>
                </StyledCalendarHeaderContainer>
            }
        >
            <ReactBigCalendar
                views={{
                    week: true,
                }}
                defaultView='week'
                currentDate={currentDate}
                onDateChange={handleDateChange}
                events={bookingEvents}
                components={{
                    header: DayColumnWrapper,
                }}
                onSelectSlot={onSelectSlot}
                onDropSlot={onDropSlot}
                onEventResize={onEventResize}
                onClickEvent={onClickEvent}
                onKeyPress={onKeyPress}
            />
        </DashboardLayout>
    )
}
