import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AttachmentsApi } from 'entities/orderAttachments/api/api'
import { Document } from 'entities/orderAttachments/model/types'
import { QueryKeys } from 'entities/orderAttachments/model/hooks/constants'

export const useGetAllDocumentsQuery = (
    orderId: number | null,
    paymentId?: number | null,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetDocuments, orderId],
            queryFn: orderId
                ? () => AttachmentsApi.getAllDocuments(orderId, paymentId)
                : () => {},
            enabled: !!orderId,
            ...(options ?? {}),
        })

    return {
        documents: data as Document[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
