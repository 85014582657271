export const initialValues = {
    role: "",
    type: 0,
    username: '',
    phone: '',
    email: '',
    password: '',
    profile_photo_hash_name: '',
    user_ad_personal_info: {
        surname: '',
        first_name: '',
        patronymic: '',
        passport_data: {
            serial: '',
            division_code: '',
            issued_by: '',
            issue_date: ''
        },
        legal_entity_data: {
            city: '',
            street: '',
            house: '',
            appartment_number: '',
            index: ''
        },
        document_photos_to_create_hash_names: ['']
    }
};