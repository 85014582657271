import { DashboardLayout } from "app/layout"
import { Button } from "shared/components/Button"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"
import { AreasTabs } from "features/AreasTabs"
import { useNavigate, useParams } from "react-router-dom"
import { AREASCREATE, AREASLIST } from "shared/consts/routingConsts"
import { AreasProfile } from "features/Areas/ui/AreasProfile"
import { axiosApi } from "shared/api/axios"
import { closeModal, openModal } from "shared/redux/slice/modalSlice"
import { useEffect, useRef, useState } from "react"
import { FormikHelpers } from "formik"
import { useDispatch } from "react-redux"
import { useQueryClient } from "@tanstack/react-query"

export const AreasProfilePages = () => {
    const {id} = useParams();
    
    const queryClient = useQueryClient()
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [disabledButtonEdit, setDisabledButtonEdit] = useState<boolean>(false);

    const [disabled, setDisabled] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    
    const formikRef = useRef<FormikHelpers<any>>(null);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
        if (isEditable && formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const cancelEdit = async () => {
        setIsEditable(false);
        setEditButtonText("Редактировать");
        formikRef.current?.resetForm();
        dispatch(closeModal());
    }

    const handleDeleteProfile = async () => {
        await axiosApi.delete(`/api/v1/areas/area/${id}`);
        await queryClient.refetchQueries({
            queryKey: ['getAreasList'],
            exact: false,
        });
        dispatch(closeModal());
        navigate(AREASLIST)
    }

    useEffect(() => {
        const permissions = JSON.parse(localStorage.getItem('permissionsUsers') as string);

        if(permissions !== undefined) {
            setDisabledButton(permissions.areas_permissions.some((item: any) => item.id === Number(id)));
            setDisabledButtonEdit(permissions.edit_permissions.some((item: any) => item.name === "is_edit_access"));
        }

    }, [id])


    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />

                {disabledButtonEdit && 
                    <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                        {isEditable ? (
                            <>
                                <Button themeType={isEditable ? "primary" : "outline-2"} onClick={toggleEdit} disabled={disabled}>{editButtonText}</Button>
                                <Button themeType={"outline-2"} onClick={cancelEdit}>Отменить</Button>
                            </>
                        ) : (
                            <>
                                {disabledButton &&
                                    <>
                                        <Button 
                                            themeType="delete" 
                                            onClick={() => dispatch(openModal({
                                                isOpen: true,
                                                content: (
                                                    <>
                                                        Вы точно хотите удалить зал?
                                                    </>
                                                ),
                                                config: {
                                                    title: "Вы точно хотите удалить?",
                                                    btns: [
                                                        {
                                                            onClick: () => handleDeleteProfile(),
                                                            title: "Да",
                                                            active: true
                                                        },
                                                        {
                                                            onClick: () => dispatch(closeModal()),
                                                            title: "Нет",
                                                            active: false
                                                        }
                                                    ]
                                                }
                                            }))}
                                        >
                                            Удалить
                                        </Button>
                                        <Button themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                                    </>
                                }
                                <Button onClick={() => navigate(AREASCREATE)}>+Добавить зал</Button>
                            </>
                        )}
                    </div>
                }
            </div>
        }>
            <AreasTabs />
            <AreasProfile
                setDisabled={setDisabled}
                isEditable={isEditable}
                formikRef={formikRef}
                setIsEditable={setIsEditable}
                setEditButtonText={setEditButtonText}
            />
        </DashboardLayout>
    )
}