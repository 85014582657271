import styled from "styled-components";

export const WrapperSettingProfile = styled.div`
    width: 100%;
    position: relative;
`

export const SettingsBtnsRow = styled.div`
    position: absolute;
    display: flex;
    gap: 10px;
    right: 0;
    top: 0;
`

export const SettingThemeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title {
        color: #282A42;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }
`

export const SettingThemeList = styled.div`
    display: flex;
    position: relative;
    gap: 20px;
    .block {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 24px;
            height: 24px;
        }
    }
    .blockOther {
        background: url('/png.jpg') no-repeat;
        background-size: cover;
    }
    .menuButton {
        background: none;
        outline: none;
        border: none;
        padding: 0;
    }
`;

export const SettingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    .title {
        color: #282A42;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
    }
`

export const SettingRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .notificationBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #D8D8D8;
        font-size: 18px;
        font-weight: 500;
        &__checkbox-list {
            display: flex;
            gap: 30px;
            align-items: center;
            label {
                user-select: none;
                display: flex;
                gap: 10px;
                align-items: center;
            }
            &__delete {
                padding: 0;
                width: 40px;
                svg {
                    stroke: #fff;
                    width: 20px;
                    height: 20px;
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
        &__input {
            display: flex;
            gap: 6px;
            input {
                width: 276px;
            }
            button {
                width: 44px;
                padding: 0;
            }
        }
    }
    .accordion__setting {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 0;
        .title {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 500;
        }
    }
    .newRole__setting {
        border-bottom: none;
    }
`

export const WrapperSettingRole = styled.div`
    width: 100%;
    .title {
        color: #282A42;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 30px;
    }
    .toggle-arrow {
        position: relative;
        top: 3px;
    }
    .rowCreateRole {
        .title {
            font-size: 18px;
            font-weight: 500;
            color: #282A42;
            margin-bottom: 0;
        }
        &-btn {
            width: 44px;
        }
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__btns {
            display: flex;
            gap: 10px;
            align-items: center;
            .rowCreateRole-btn {
                padding: 0;
                width: 39px;
                height: 39px;
                &.delete {
                    border-color: #E1E4EA;
                }
            }
        }
    }
`

export const WrapperOrgHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
`;

export const WrapperOrgHeaderTitle = styled.div`
    font-size: 24px;
    line-height: 20px;
    font-weight: 600;
    color: #282A42;
`;

export const WrapperOrgHeaderDesc = styled.div`
    color: #8181A5;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
`;

export const AccordionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    .subtitle {
        color: #8181A5;
        font-size: 18px;
        font-weight: 500;
    }
    .accordionRow {
        display: flex;
        column-gap: 30px;
        row-gap: 10px;
        flex-wrap: wrap;
        label {
            display: flex;
            gap: 10px;
            align-items: center;
            span {
                font-size: 18px;
                font-weight: 500;
                color: #282A42;
            }
        }
    }
`