import styled from 'styled-components'

export const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e4ea;
    border-radius: 8px;
    gap: 10px;
    padding: 9px;
`
