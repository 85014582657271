import { FC } from 'react'

import { NomenclatureListPreviewItem } from 'features/OrderPreview/ui/components/NomenclatureListPreviewItem'
import { NomenclatureListPreviewPrice } from 'features/OrderPreview/ui/components/NomenclatureListPreviewPrice'
import { OrderBooking } from 'entities/orders/model'
import { roundNumber } from 'shared/utils/roundNumber'

import { getBookingRange } from './utils'

import {
    NestedList,
    StyledDescriptionItem,
    StyledDescriptionPrice,
    StyledFooterColumn,
    StyledFooterRow,
} from './styles'
import { AreaListPreviewItem } from 'features/OrderPreview/ui/components/AreaListPreviewItem'

interface BookingTotalPricePreviewProps {
    booking: OrderBooking
    isOrderItemMode?: boolean
}

export const BookingTotalPrice: FC<BookingTotalPricePreviewProps> = ({
    booking,
}) => {
    if (!booking?.totalPriceWithDiscount && !booking?.areaPrice) {
        return null
    }

    const areaPrice = roundNumber(booking?.areaPrice)
    const areaAbsolutDiscount = roundNumber(booking?.areaAbsolutDiscount)
    const totalAreaPrice = roundNumber(booking.areaTotalPriceWithDiscount ?? 0)
    const nomenclaturePrice = roundNumber(
        booking?.nomenclaturePriceWithDiscount,
    )
    const bookingRange = getBookingRange(booking)

    return (
        <StyledDescriptionItem>
            <li>
                <StyledFooterColumn>
                    <StyledFooterRow>
                        <AreaListPreviewItem
                            areaPrice={areaPrice ?? 0}
                            areaDiscount={areaAbsolutDiscount ?? 0}
                            range={bookingRange.label}
                            areaName={booking?.area?.name ?? ''}
                        />
                        <StyledDescriptionPrice>
                            ₽{totalAreaPrice}
                        </StyledDescriptionPrice>
                    </StyledFooterRow>

                    {Boolean(booking?.nomenclatures?.length) && (
                        <div>
                            <StyledFooterRow>
                                <StyledDescriptionItem>
                                    Дополнительно:
                                </StyledDescriptionItem>
                                <StyledDescriptionPrice>
                                    ₽{nomenclaturePrice}
                                </StyledDescriptionPrice>
                            </StyledFooterRow>
                        </div>
                    )}

                    <NestedList>
                        {Boolean(booking?.nomenclatures?.length) && (
                            <>
                                {booking.nomenclatures
                                    .filter(
                                        nomenclature => nomenclature.count > 0,
                                    )
                                    .map(nomenclature => (
                                        <StyledFooterRow key={nomenclature.id}>
                                            <StyledDescriptionItem>
                                                <NomenclatureListPreviewItem
                                                    nomenclature={nomenclature}
                                                    range={bookingRange.diff}
                                                />
                                            </StyledDescriptionItem>
                                            <StyledDescriptionPrice
                                                isHideListPadding
                                            >
                                                <NomenclatureListPreviewPrice
                                                    nomenclature={nomenclature}
                                                />
                                            </StyledDescriptionPrice>
                                        </StyledFooterRow>
                                    ))}
                            </>
                        )}
                    </NestedList>
                </StyledFooterColumn>
            </li>
        </StyledDescriptionItem>
    )
}
