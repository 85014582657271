import { axiosApi } from "shared/api/axios";
import { IPropsRolesList, IPropsRolesSingle } from "../types";
import { RolesEndpoint } from "./constants";

export const getRoles = async (): Promise<IPropsRolesList> => {
    try {
        const response = await axiosApi.get<IPropsRolesList>(`${RolesEndpoint.roles}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка получения роли");
    }
};


export const getRolesSingle = async (id: number): Promise<IPropsRolesSingle> => {
    try {
        const response = await axiosApi.get<IPropsRolesSingle>(`${RolesEndpoint.rolesSingle}${id}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка получения роли");
    }
};
