import React, { useCallback, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Icon } from 'shared/components/Icons'
import { useAppDispatch } from 'shared/redux/hooks'
import { logout } from 'features/auth/model/authSlice'
import { Box, StyledItem } from './style'
import { sidebarMenuData } from 'shared/consts/sidebarMenuData'
import { SidebarMenuTypes } from 'shared/types/sidebarMenuTypes'

export const LeftSidebar: React.FC = () => {
    const [sidebarList, setSidebarList] = useState<SidebarMenuTypes[]>([]);
    
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
        // Получаем данные из localStorage
        const permissions = JSON.parse(localStorage.getItem('permissionsUsers') || '{}');
        
        if (permissions && permissions.pages_permissions) {
            const activePermissions = sidebarMenuData.filter(item2 =>
                permissions.pages_permissions.some((item1: any) => item1.id === item2.id)
            );
            setSidebarList(activePermissions);
        }

    }, []);  // Пустой массив зависимостей, т.к. мы хотим сделать это только при первом рендере

    return (
        <Box>
            <div>
                <Link to='/'>
                    <Icon name='logo' />
                </Link>
                {sidebarList && sidebarList.map((item, index) => (
                    <StyledItem key={index}>
                        <NavLink
                            to={item.link}
                            className={({ isActive }) =>
                                isActive ? 'active' : undefined
                            }
                        >
                            <Icon name={item.icon} />
                        </NavLink>
                    </StyledItem>
                ))}
            </div>
            <StyledItem>
                <Link to='/auth/sign-in' onClick={() => handleClick()}>
                    <Icon name='exit' />
                </Link>
            </StyledItem>
        </Box>
    );
};
