import { axiosApi } from "shared/api/axios";

export const deleteMethodPayments = async (id: number): Promise<any> => {
    try {
        const response = await axiosApi.delete(`/api/v1/payments/payment/methods/${id}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Ошибка удаления метода оплаты");
    }
};
