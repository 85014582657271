export const getInitialValues = (profileData: any) => ({
    phone: profileData.phone || '',
    email: profileData.email || '',
    username: profileData.username || '',
    user_ad_personal_info: {
        surname: profileData.user_ad_personal_info.surname || '',
        first_name: profileData.user_ad_personal_info.first_name || '',
        patronymic: profileData.user_ad_personal_info.patronymic || '',
    },
    profile_photo_hash_name: profileData.profile_photo ? profileData.profile_photo.hash_name : null
});