import {  Outlet, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { AREASLIST } from "shared/consts/routingConsts"

export const ManagementPages = () => {

    const navigate = useNavigate()
    
    useEffect(() => {
        if (location.pathname === '/management') {
            navigate(AREASLIST)
        }
    }, [])

    return (
        <Outlet />
    )
}