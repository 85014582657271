import { FC } from 'react'
import { Nomenclature } from 'entities/orders/model'
import { NomenclatureOldPriceLabel } from 'entities/bookings/ui/styles'

interface NomenclatureListPreviewItemProps {
    nomenclature: Nomenclature
    range: number;
}

export const NomenclatureListPreviewItem: FC<
    NomenclatureListPreviewItemProps
> = ({ nomenclature, range }) => (
    <li>
        {nomenclature.name}{' '}
        {nomenclature.price && nomenclature.absolutDiscount > 0 ? (
            <>
                <NomenclatureOldPriceLabel>
                    ₽{nomenclature.price}
                </NomenclatureOldPriceLabel>{' '}
                ₽{nomenclature.price - nomenclature.absolutDiscount}
            </>
        ) : (
            <>₽{nomenclature.price}</>
        )}{' '}
        ({nomenclature.count}шт) {nomenclature.type === 0 && `x ${range}ч`}
    </li>
)
