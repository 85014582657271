import React, { FC } from 'react'

import { NomenclatureListPreviewItem } from 'features/Booking/ui/components/Refund/NomenclatureListPreviewItem'
import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'
import { Nomenclature, OrderBooking } from 'entities/orders/model'
import { Flex } from 'shared/components/Flex'
import { Counter } from 'shared/components/Counter'
import { Checkbox } from 'shared/components/Checkbox'
import { roundNumber } from 'shared/utils/roundNumber'

import { AreaListPreviewItem } from './AreaListPreviewItem'
import { StyledDescriptionItem, StyledFooterRow } from './styles'

interface RefundItemProps {
    booking: OrderBooking
    onChangeBookingsToRefund: (
        booking: OrderBooking,
        nomenclature?: Nomenclature,
    ) => void
    isBookingChecked: (booking: OrderBooking) => boolean
    isNomenclatureChecked: (nomenclature: Nomenclature) => boolean
    onChangeAreaCount: (booking: OrderBooking, newCount: number) => void
    onChangeNomenclaturesCount: (
        nomenclature: Nomenclature,
        newCount: number,
    ) => void
    getAreaCount: (booking: OrderBooking) => number
    getNomenclaturesCount: (
        booking: OrderBooking,
        nomenclature: Nomenclature,
    ) => number
}

export const RefundItem: FC<RefundItemProps> = ({
    booking,
    onChangeBookingsToRefund,
    isBookingChecked,
    isNomenclatureChecked,
    onChangeAreaCount,
    onChangeNomenclaturesCount,
    getAreaCount,
    getNomenclaturesCount,
}) => {
    if (!booking?.totalPriceWithDiscount && !booking?.areaPrice) {
        return null
    }

    const areaPrice = roundNumber(booking?.areaPrice)
    const areaAbsolutDiscount = roundNumber(booking?.areaAbsolutDiscount)
    const bookingRange = getBookingRange(booking)

    return (
        <StyledDescriptionItem>
            <Flex flexDirection='column' style={{ gap: 5 }}>
                <StyledFooterRow>
                    <Flex gap={5}>
                        <Checkbox
                            name='asd'
                            checked={isBookingChecked(booking)}
                            onChange={() => onChangeBookingsToRefund(booking)}
                        />
                        <AreaListPreviewItem
                            areaPrice={areaPrice ?? 0}
                            areaDiscount={areaAbsolutDiscount ?? 0}
                            range={bookingRange.label}
                            areaName={booking?.area?.name ?? ''}
                        />
                    </Flex>
                    <Counter
                        count={getAreaCount(booking)}
                        onDecreaseCount={() =>
                            onChangeAreaCount(
                                booking,
                                getAreaCount(booking) - 1,
                            )
                        }
                        onIncreaseCount={() =>
                            onChangeAreaCount(
                                booking,
                                getAreaCount(booking) + 1,
                            )
                        }
                    />
                </StyledFooterRow>

                <Flex flexDirection='column' style={{ marginLeft: 20, gap: 5 }}>
                    {Boolean(booking?.nomenclatures?.length) && (
                        <>
                            {booking.nomenclatures
                                .filter(nomenclature => nomenclature.count > 0)
                                .map(nomenclature => (
                                    <StyledFooterRow key={nomenclature.id}>
                                        <Flex gap={5}>
                                            <Checkbox
                                                name='asd'
                                                checked={isNomenclatureChecked(
                                                    nomenclature,
                                                )}
                                                onChange={() =>
                                                    onChangeBookingsToRefund(
                                                        booking,
                                                        nomenclature,
                                                    )
                                                }
                                            />
                                            <StyledDescriptionItem>
                                                <NomenclatureListPreviewItem
                                                    nomenclature={nomenclature}
                                                />
                                            </StyledDescriptionItem>
                                        </Flex>
                                        <Counter
                                            count={getNomenclaturesCount(
                                                booking,
                                                nomenclature,
                                            )}
                                            onDecreaseCount={() =>
                                                onChangeNomenclaturesCount(
                                                    nomenclature,
                                                    getNomenclaturesCount(
                                                        booking,
                                                        nomenclature,
                                                    ) - 1,
                                                )
                                            }
                                            onIncreaseCount={() =>
                                                onChangeNomenclaturesCount(
                                                    nomenclature,
                                                    getNomenclaturesCount(
                                                        booking,
                                                        nomenclature,
                                                    ) + 1,
                                                )
                                            }
                                        />
                                    </StyledFooterRow>
                                ))}
                        </>
                    )}
                </Flex>
            </Flex>
        </StyledDescriptionItem>
    )
}
