import React, { FC } from 'react'
import { StyledContainer } from 'shared/components/Counter/styles'
import { Icon } from 'shared/components/Icons'

interface CounterProps {
    count: number
    onDecreaseCount: () => void
    onIncreaseCount: () => void
}

export const Counter: FC<CounterProps> = ({
    count,
    onIncreaseCount,
    onDecreaseCount,
}) => (
    <StyledContainer>
        <Icon style={{ cursor: 'pointer' }} onClick={onDecreaseCount} name='minus' />
        <span>{count}</span>
        <Icon style={{ cursor: 'pointer' }} onClick={onIncreaseCount} name='mediumPlus' />
    </StyledContainer>
)
