import { Checkbox } from "shared/components/Checkbox"
import { IPropsPermissions } from "../types"
import { AccordionContent } from "./style"
import { useGetRolesSingle } from "../hooks/useGetRolesSingle"
import { useEffect, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { axiosApi } from "shared/api/axios"
import { useDispatch } from "react-redux"
import { showNotification } from "shared/redux/slice/notificationSlice"

interface IPropsPermissionsBlock {
    id: number
    permissions: IPropsPermissions
}

export const PermissionsBlock = ({
    permissions,
    id
}:IPropsPermissionsBlock) => {

    const dispatch = useDispatch();
    const {
        value,
        queryInfo: { refetch }
    } = useGetRolesSingle(id);

    const [selectedPermissions, setSelectedPermissions] = useState({
        areas_permissions: [] as { id: number; name: string }[],
        pages_permissions: [] as { id: number; name: string }[],
        edit_permissions: [] as { id: number; name: string }[],
    });
    
    useEffect(() => {

        if (value?.permissions) {
            
            setSelectedPermissions({
                areas_permissions: value.permissions.areas_permissions,
                pages_permissions: value.permissions.pages_permissions,
                edit_permissions: value.permissions.edit_permissions,
            });
            
            const usersLocalStore = localStorage.getItem('users');
            
            if(usersLocalStore) {
                const userRole = JSON.parse(usersLocalStore);
                if(userRole.role.id === value.id) {
                    localStorage.setItem('permissionsUsers', JSON.stringify(value?.permissions));
                }
            }
        }

    }, [value]);

    const mutation = useMutation({
        mutationFn: ({ newPermissions, deletedPermissions }: { newPermissions: number[]; deletedPermissions: number[] }) => {
            return axiosApi.patch(`/api/v1/roles/role/${id}`, {
                new_permissions: newPermissions,
                deleted_permissions: deletedPermissions
            });
        },
        onError: () => {
            dispatch(showNotification({
                message: "Ошибка!",
                type: "error",
            }));
        },
        onSuccess: () => {
            dispatch(showNotification({
                message: "Роль обновлена",
                type: "success",
            }));
            refetch();
        }
    })

    const handleCheckboxChange = (category: keyof IPropsPermissions, item: { id: number; name: string }) => {
        setSelectedPermissions((prevState) => {
            const isSelected = prevState[category].some((perm) => perm.id === item.id);
            const updatedCategory = isSelected
                ? prevState[category].filter((perm) => perm.id !== item.id)
                : [...prevState[category], item];

            const updatedPermissions = {
                ...prevState,
                [category]: updatedCategory,
            };

            const newPermissions = !isSelected ? [item.id] : [];
            const deletedPermissions = isSelected ? [item.id] : [];

            const updatePermissions = async () => {
                await mutation.mutateAsync({ newPermissions, deletedPermissions });
            }

            updatePermissions();

            return updatedPermissions;
        });
    };

    return (
        <>
            <AccordionContent>
                <div className="subtitle">Доступ к помещениям</div>
                <div className="accordionRow">
                    {permissions?.areas_permissions.map((item, index) => (
                        <label key={index}>
                            <Checkbox
                                name={`areas_permissions_${item.id}`}
                                checked={selectedPermissions.areas_permissions.some((perm) => perm.id === item.id)}
                                onChange={() => handleCheckboxChange("areas_permissions", item)}
                            />
                            <span>{item.name}</span>
                        </label>
                    ))}
                </div>
            </AccordionContent>
            <AccordionContent>
                <div className="subtitle">Доступ к страницам</div>
                <div className="accordionRow">
                    {permissions?.pages_permissions.map((item, index) => (
                        <label key={index}>
                            <Checkbox
                                name={`pages_permissions_${item.id}`}
                                checked={selectedPermissions.pages_permissions.some((perm) => perm.id === item.id)}
                                onChange={() => handleCheckboxChange("pages_permissions", item)}
                            />
                            <span>{item.name}</span>
                        </label>
                    ))}
                </div>
            </AccordionContent>
            <AccordionContent>
                <div className="subtitle">Возможность редактировать</div>
                <div className="accordionRow">
                    {permissions?.edit_permissions.map((item, index) => (
                        <label key={index}>
                            <Checkbox
                                name={`edit_permissions_${item.id}`}
                                checked={selectedPermissions.edit_permissions.some((perm) => perm.id === item.id)}
                                onChange={() => handleCheckboxChange("edit_permissions", item)}
                            />
                            <span>Да</span>
                        </label>
                    ))}
                </div>
            </AccordionContent>
        </>
    )
}