import moment from "moment";

export const getInitialValues = (profileData: any) => ({
    phone: profileData.phone || '',
    email: profileData.email || '',
    role: profileData.role.name || '',
    roleID: profileData.role.id || '',
    created_at: moment(profileData.created_at).format('DD.MM.YYYY') || '',
    user_ad_personal_info: {
        surname: profileData.user_ad_personal_info.surname || '',
        first_name: profileData.user_ad_personal_info.first_name || '',
        patronymic: profileData.user_ad_personal_info.patronymic || '',
        passport_data: profileData.user_ad_personal_info.passport_data ? {
            serial: profileData.user_ad_personal_info.passport_data.serial || '',
            division_code: profileData.user_ad_personal_info.passport_data.division_code || '',
            issued_by: profileData.user_ad_personal_info.passport_data.issued_by || '',
            issue_date: profileData.user_ad_personal_info.passport_data.issue_date || '',
        } : null,
        legal_entity_data: profileData.user_ad_personal_info.legal_entity_data ? {
            city: profileData.user_ad_personal_info.legal_entity_data.city || '',
            street: profileData.user_ad_personal_info.legal_entity_data.street || '',
            house: profileData.user_ad_personal_info.legal_entity_data.house || '',
            appartment_number: profileData.user_ad_personal_info.legal_entity_data.appartment_number || '',
            index: profileData.user_ad_personal_info.legal_entity_data.index || '',
        } : null,
    },
});