import React, { FC, FormEvent, useState } from 'react'

import { Nomenclature as OrderNomenclature } from 'entities/orders/model'
import { useCounterpartyAsyncAutocompleteOptions } from 'entities/nomenclatures/model'
import { Icon } from 'shared/components/Icons'
import {
    Autocomplete,
    AutocompleteOption,
} from 'shared/components/Autocomplete'
import { Counter } from 'shared/components/Counter'

import {
    StyledAddedNomenclature,
    StyledAddedNomenclatureContainer,
    StyledAutocompleteWrapper,
    StyledContainer,
    StyledCrossIconContainer,
    StyledNomenclatureInputContainer,
} from './styles'
import { NomenclaturesInputProps } from './types'
import { adaptNomenclaturesToOption } from './utils'
import { NomenclatureLabel } from './NomenclatureLabel'
import { DiscountInputContainer } from './DiscountInputContainer'

export const NomenclaturesInput: FC<NomenclaturesInputProps> = ({
    disabled,
    values,
    onAddNomenclature,
    onRemoveNomenclature,
}) => {
    const [selectedValue, setSelectedValue] =
        useState<AutocompleteOption | null>()
    const [count, setCount] = useState<number>(0)
    const [absolutDiscount, setAbsolutDiscount] = useState<number | null>(null)
    const [percentDiscount, setPercentDiscount] = useState<number | null>(null)

    const isValid = !!selectedValue && count > 0

    const [searchString, setSearchString] = useState<string>()
    const { nomenclatures, fetchNextPage, isFetching } =
        useCounterpartyAsyncAutocompleteOptions({
            search_param: searchString,
        })

    const options: AutocompleteOption[] =
        nomenclatures?.map(adaptNomenclaturesToOption) ?? []

    const onChangeSelectedValue = (option: AutocompleteOption) => {
        setSelectedValue(option)
        if (!count) {
            setCount(1)
            return
        }
        if (!option) {
            setCount(0)
            return
        }
    }

    const onRemoveSelectedValue = async (option: OrderNomenclature) => {
        if (onRemoveNomenclature) {
            onRemoveNomenclature(option)
        }
    }

    const onIncreaseCount = () => {
        setCount(prev => prev + 1)
    }

    const onDecreaseCount = () => {
        setCount(prev => prev - 1)
    }

    const onAbsolutDiscountChange = (e: FormEvent) => {
        const newAbsolutDiscount = Math.abs(
            Number((e.target as HTMLInputElement).value),
        )
        if (Number.isNaN(newAbsolutDiscount)) {
            return
        }
        setAbsolutDiscount(newAbsolutDiscount)
        setPercentDiscount(null)
    }

    const onPercentDiscountChange = (e: FormEvent) => {
        const newPercentDiscount = Math.abs(
            Number((e.target as HTMLInputElement).value),
        )
        if (Number.isNaN(newPercentDiscount)) {
            return
        }
        setPercentDiscount(newPercentDiscount)
        setAbsolutDiscount(null)
    }

    const onAdd = async () => {
        setCount(0)
        setAbsolutDiscount(null)
        setPercentDiscount(null)
        setSelectedValue(null)

        if (onAddNomenclature && selectedValue) {
            onAddNomenclature({
                id: Number(selectedValue.value),
                name: selectedValue.label,
                count,
                percentDiscount: percentDiscount ?? 0,
                absolutDiscount: absolutDiscount ?? 0,
                type: 0,
            })
        }
    }

    return (
        <StyledContainer>
            <StyledNomenclatureInputContainer>
                <StyledAutocompleteWrapper>
                    <Autocomplete
                        options={options}
                        onChange={onChangeSelectedValue as any}
                        value={selectedValue}
                        placeholder='-'
                        disabled={disabled}
                        isLoading={isFetching}
                        onInputChange={setSearchString}
                        onLoadMore={fetchNextPage}
                    />
                </StyledAutocompleteWrapper>

                <Counter
                    count={count}
                    onDecreaseCount={onDecreaseCount}
                    onIncreaseCount={onIncreaseCount}
                />
            </StyledNomenclatureInputContainer>

            <DiscountInputContainer
                absolutDiscount={absolutDiscount}
                onAbsolutDiscountChange={onAbsolutDiscountChange}
                percentDiscount={percentDiscount}
                onPercentDiscountChange={onPercentDiscountChange}
                onAdd={onAdd}
                disabledAddButton={!isValid}
            />
            <StyledAddedNomenclatureContainer>
                {(values ?? []).map((val, index) => (
                    <StyledAddedNomenclature key={index}>
                        <NomenclatureLabel nomenclature={val} />
                        <StyledCrossIconContainer
                            onClick={() => onRemoveSelectedValue(val)}
                        >
                            <Icon name='deleteRoundedIcon' />
                        </StyledCrossIconContainer>
                    </StyledAddedNomenclature>
                ))}
            </StyledAddedNomenclatureContainer>
        </StyledContainer>
    )
}
