import { SidebarProvider } from 'features/RightSidebar/components/SidebarProvider'
import { LeftSidebar } from 'features/LeftSidebar'
import { RightSidebar } from 'features/RightSidebar'
import {
    StyledWrapper,
    StyledContainer,
    StyledContent,
    StyledHeader,
} from './style'
import { useSelector } from 'react-redux'
import { isModal, isNotification } from 'shared/redux/store'
import { Notifications } from 'shared/components/Notifications'
import { Modal } from 'shared/components/Modal'
import { Suspense } from 'react'

interface IPropsDashboardLayout {
    children: React.ReactNode
    header?: React.ReactNode
}

export const DashboardLayout = ({ children, header }: IPropsDashboardLayout) => {
    const modal = useSelector(isModal); 
    const notification = useSelector(isNotification);

    const btnsArray =
        modal.isConfig?.btns?.map((item) => ({
            onClick: item.onClick,
            title: item.title,
            active: item.active,
        })) || [];

    return (
        <SidebarProvider>
            <StyledWrapper>
                <LeftSidebar />
                <StyledContainer>
                    {header && <StyledHeader>{header}</StyledHeader>}
                    <Suspense fallback={<div>Загрузка данных...</div>}>
                        <StyledContent full={!!header}>
                            {children}
                        </StyledContent>
                    </Suspense>
                </StyledContainer>
                <RightSidebar />
            </StyledWrapper>
            {modal.isOpen && (
                <Modal
                    title={modal.isConfig!.title}
                    isOpen={modal.isOpen}
                    isFullScreen={modal.isFullScreen}
                    btns={btnsArray}
                >
                    {modal.isContent}
                </Modal>
            )}
            {notification.isType && (
                <Notifications
                    title={notification.isType}
                    message={notification.isMessage}
                    errMessage={notification.ErrMessage}
                />
            )}
        </SidebarProvider>
    );
};