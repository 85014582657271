import { Form, Formik, FormikHelpers } from "formik"
import { useEffect, useRef, useState } from "react"
import { Avatar } from "shared/components/Avatar";
import { UploadAvatar } from "shared/containers/UploadAvatar";
import { StyledLine, WrapperAvatar } from "shared/styles/GlobalStyle";
import { SettingsBtnsRow, WrapperSettingProfile } from "./style";
import { WrapperFormRow } from "shared/components/WrapperFormRow";
import { ProfileFormData } from "../model/profileFormData";
import { Button } from "shared/components/Button";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../api/getProfile";
import { Loader } from "shared/components/Loader";
import { useDispatch } from "react-redux";
import { getInitialValues } from "../model/getInitialValues";
import { closeModal } from "shared/redux/slice/modalSlice";
import { useFileUploadUp } from "shared/hooks/useFileUploadUp";
import { patchProfile } from "../api/patchProfile";
import { showNotification } from "shared/redux/slice/notificationSlice";

export const SettingProfile = () => {

    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [isEditable, setIsEditable] = useState(false);
    const [editButtonText, setEditButtonText] = useState("Редактировать");
    const [name, setName] = useState('');
    const [photo, setPhoto] = useState<File | null>(null);
    const { handleFileUpload } = useFileUploadUp();
    
    const formikRef = useRef<FormikHelpers<any>>(null);

    const toggleEdit = () => {
        setIsEditable(!isEditable);
        setEditButtonText(isEditable ? "Редактировать" : "Сохранить");
        if (isEditable && formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    const cancelEdit = async () => {
        setIsEditable(false);
        setEditButtonText("Редактировать");
        formikRef.current?.resetForm();
        dispatch(closeModal());
    }

    const {isLoading, data, refetch} = useQuery({
        queryKey: ["getProfile"],
        queryFn: () => getProfile(),
        refetchOnWindowFocus: false
    });

    const [currentPhoto, setCurrentPhoto] = useState<string | null>(data?.profile_photo?.read_url ?? null);

    useEffect(() => {
        if (data && data.user_ad_personal_info) {
            const { surname, first_name, patronymic } = data.user_ad_personal_info;
            const fullName = [surname, first_name, patronymic].filter(Boolean).join(' ');
            setName(fullName);
        }
    }, [isLoading, data]);

    const handlePhotoChange = (file: File) => {
        setPhoto(file);
        setCurrentPhoto(URL.createObjectURL(file));
    };

    const onSubmit = async(values: any) => {
        const { uploadedPhotoInfo } = await handleFileUpload(photo, []);

        const updatedValues = {
            ...values,
        };

        if (uploadedPhotoInfo) {
            updatedValues.profile_photo_hash_name = uploadedPhotoInfo.hash_name;
        }

        patchProfile(updatedValues, data?.id).then(() => {})
        .finally(() => {
            refetch();
            dispatch(showNotification({message: "Успешно!", type: "success"}));
        }).catch(() => {
            dispatch(showNotification({message: "Ошибка", type: "error"}))
        })
    }

    useEffect(() => {
        if (!currentPhoto) {
            setCurrentPhoto(data?.profile_photo?.read_url ?? null);
        }
    }, [data?.profile_photo?.read_url, currentPhoto]);


    if(isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <WrapperSettingProfile>
            <SettingsBtnsRow>
            {isEditable ? (
                <>
                    <Button themeType={isEditable ? "primary" : "outline-2"} onClick={toggleEdit} disabled={disabled}>{editButtonText}</Button>
                    <Button themeType={"outline-2"} onClick={cancelEdit}>Отменить</Button>
                </>
            ) : (
                <>
                    <Button themeType={"outline-2"} onClick={toggleEdit}>{editButtonText}</Button>
                </>
            )
        }
            </SettingsBtnsRow>
            <Formik
                innerRef={formikRef as any}
                initialValues={getInitialValues(data)}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(isValid) => {
                    setDisabled(!isValid);
                    return (
                        <Form>
                            <WrapperAvatar>
                                {isEditable ? (
                                    <UploadAvatar 
                                        initialPhoto={currentPhoto}
                                        onPhotoChange={handlePhotoChange}
                                    />
                                ) : (
                                    <Avatar 
                                        photo={data?.profile_photo ? data?.profile_photo?.read_url : ''} 
                                        name={name}
                                        jobs={data?.role?.name} 
                                    />
                                )}
                            </WrapperAvatar>
                            <StyledLine margin="20px 0"/>
                            <WrapperFormRow
                                title="Общие данные" 
                                formData={ProfileFormData.map(row => 
                                    row.map(item => ({ 
                                        ...item, 
                                        disabled: !isEditable,
                                        placeholder: isEditable ? item.placeholder : ""
                                    })
                                ))} 
                            />
                        </Form>
                    )
                }}
            </Formik>
        </WrapperSettingProfile>
    )
}