import { Checkbox } from "shared/components/Checkbox"
import { SettingRow, SettingWrapper } from "./style"
import { useEffect, useState } from "react"
import { Loader } from "shared/components/Loader";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getNotifications } from "../api/getNotifications";
import { IPropsNotificationsList, NotificationKeysList } from '../types'
import { patchNotifications } from "../api/patchNotifications";

// const notificationList = ["Уведомления", "Акции", "Отмена брони", "Освободилось время", "Новое бронирование", "Изменение бронирования"];

const notificationList: NotificationKeysList = [
    {
        name: 'Уведомления',
        key: 'notifications',
    },
    {
        name: 'Акции',
        key: 'promotion',
    },
    {
        name: 'Отмена брони',
        key: 'booking_cansel',
    },
    {
        name: 'Освободилось время',
        key: 'time_freed_up',
    },
    {
        name: 'Новое бронирование',
        key: 'new_booking',
    },
    {
        name: 'Изменение бронирования',
        key: 'change_booking',
    },
]

export const SettingNotifications = () => {
    const [notificationMethods, setNotificationMethods] =
        useState<IPropsNotificationsList | null>(null)

    const { isLoading, data } = useQuery({
        queryKey: ['getNotifications'],
        queryFn: () => {
            return getNotifications()
        },
        placeholderData: keepPreviousData,
        retry: 3,
    })

    useEffect(() => {
        if (data && !notificationMethods) {
            setNotificationMethods(data.notifications)
        }
    }, [data]);
    
    const handleCheckboxChange = (key: keyof IPropsNotificationsList, index: number) => {
        setNotificationMethods((prev) => {
            if (!prev) return prev;
            const updated = { ...prev };
            const directions = [...updated[key].notification_directions];
            directions[index] = !directions[index];
            updated[key] = { ...updated[key], notification_directions: directions };
            return updated;
        });
    };


    useEffect(() => {
        if(notificationMethods) {
            patchNotifications(notificationMethods).then().catch((err) => {
                console.log(err);
            })
        }
    }, [notificationMethods])

    // const handleNotifyChange = () => {
    //     setNotificationMethods((prev) => prev && { ...prev, is_notify: !prev.is_notify });
    // };

    if(isLoading) {
        return <Loader />
    }

    return (
        <SettingWrapper>
            <div className='title'>Настройки уведомлений</div>
            {!isLoading && data && notificationMethods && (
                <SettingRow>
                    {notificationList.map((item, index) => (
                        <div key={index} className="notificationBlock">
                            <div className="notificationBlock__title">{item.name}</div>
                            <div className="notificationBlock__checkbox-list">
                                <label>
                                    Email
                                    <Checkbox
                                        name="checkbox" 
                                        checked={notificationMethods[item.key as keyof IPropsNotificationsList]?.notification_directions[0]} 
                                        onChange={() => handleCheckboxChange(item.key as keyof IPropsNotificationsList, 0)} />
                                </label>
                                <label>
                                    Телефон
                                    <Checkbox 
                                        name="checkbox" 
                                        checked={notificationMethods[item.key as keyof IPropsNotificationsList]?.notification_directions[1]} 
                                        onChange={() => handleCheckboxChange(item.key as keyof IPropsNotificationsList, 1)} />
                                </label>
                            </div>
                        </div>
                    ))}

                    {/* {notificationMethods &&
                        <div className="notificationBlock">
                            <div className="notificationBlock__title">Не уведомлять</div>
                            <div className="notificationBlock__checkbox-list">
                                <label>
                                    <Checkbox 
                                        name="checkbox" 
                                        checked={notificationMethods.is_notify}
                                        onChange={handleNotifyChange}
                                    />
                                </label>
                            </div>
                        </div>
                    } */}
                </SettingRow>
            )}
        </SettingWrapper>
    )
}
