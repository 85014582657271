import React, { FC } from 'react'
import moment from 'moment'

import { useDetailsPaymentsForm } from 'features/DetailsPayments/model/useDetailsPaymentsForm'
import { Button } from 'shared/components/Button'
import { Autocomplete } from 'shared/components/Autocomplete'
import { Input } from 'shared/components/Input'
import { Flex } from 'shared/components/Flex'
import { Accordion } from 'shared/components/Accordion'

import { DetailsPaymentsFormStyled, DetailsPaymentsTitle } from './style'
import { DetailsPaymentsModalProps } from 'features/DetailsPayments/ui/DetailsPaymentsModal'

export const DetailsPaymentsForm: FC<DetailsPaymentsModalProps> = ({
    detailOrder,
    counterparty,
    onPaymentProcess,
    details,
}) => {
    const {
        currentOrganisation,
        organisationsOptions,
        organisationOption,
        onCurrentOrganisationChange,

        currentPaymentAccount,
        paymentAccountsOptions,
        paymentAccountsOption,
        onCurrentPaymentAccountChange,

        basis,
        onBasisChange,

        isButtonDisabled,
        process,
    } = useDetailsPaymentsForm(onPaymentProcess, details)

    return (
        <DetailsPaymentsFormStyled>
            <div className='row'>
                <div className='form'>
                    <DetailsPaymentsTitle>Детали оплаты</DetailsPaymentsTitle>
                    <Accordion title='Поставщик' isOpenByDefault>
                        <Flex flexDirection='column' gap={10}>
                            <Flex alignItems='end' gap={20}>
                                <Input
                                    label='Номер заказа'
                                    placeholder='Номер заказа'
                                    value={detailOrder.id}
                                    disabled
                                />
                                <Input
                                    label='Дата'
                                    placeholder='Дата'
                                    value={moment(detailOrder.createdAt).format(
                                        'DD.MM.YYYY',
                                    )}
                                    disabled
                                />
                            </Flex>

                            <Autocomplete
                                options={organisationsOptions}
                                value={organisationOption ?? null}
                                onChange={onCurrentOrganisationChange}
                                label='ИНН'
                                placeholder='Введите ИНН поставщика'
                                required
                            />
                            <Flex alignItems='end' gap={20}>
                                <Input
                                    name=''
                                    label='Наименование организации'
                                    value={currentOrganisation?.name ?? ''}
                                    disabled
                                    required
                                />
                                <Input
                                    name=''
                                    label='Юридический адресс поставщика'
                                    value={currentOrganisation?.legalAddress ?? ''}
                                    disabled
                                    required
                                />
                            </Flex>
                            <Flex alignItems='end' gap={20}>
                                <Input
                                    name=''
                                    label='КПП'
                                    value={currentOrganisation?.kpp ?? ''}
                                    disabled
                                    required
                                />
                                <Input
                                    name=''
                                    label='Генеральный директор'
                                    value={currentOrganisation?.ceo ?? ''}
                                    disabled
                                    required
                                />
                            </Flex>


                            <Autocomplete
                                options={paymentAccountsOptions}
                                value={paymentAccountsOption ?? null}
                                onChange={onCurrentPaymentAccountChange}
                                label='БИК Банка поставщика'
                                placeholder='Введите БИК Банка Поставщика'
                                required
                            />
                            <Flex alignItems='end' gap={20}>
                                <Input
                                    name=''
                                    label='Расчетный счет'
                                    value={currentPaymentAccount?.number ?? ''}
                                    disabled
                                    required
                                />
                                <Input
                                    name=''
                                    label='Кореспонденский счет'
                                    value={
                                        currentPaymentAccount?.correspondentNumber ?? ''
                                    }
                                    disabled
                                    required
                                />
                            </Flex>
                            <Flex alignItems='end' gap={20}>
                                <Input
                                    name=''
                                    label='БИК'
                                    value={currentPaymentAccount?.bik ?? ''}
                                    disabled
                                    required
                                />
                                <Input
                                    name=''
                                    label='Банк'
                                    value={currentPaymentAccount?.bankName ?? ''}
                                    disabled
                                    required
                                />
                            </Flex>
                        </Flex>
                    </Accordion>
                    <Accordion title='Покупатель' isOpenByDefault>
                        <Flex flexDirection='column' gap={10}>
                            <Input
                                label='Наименование организации'
                                value={
                                    counterparty.additionalData.documents
                                        .organizationName ?? ''
                                }
                                disabled
                            />
                            <Flex alignItems='end' gap={20}>
                                <Input
                                    name=''
                                    label='ИНН'
                                    value={
                                        counterparty.additionalData.documents
                                            .inn ?? ''
                                    }
                                    disabled
                                />
                                <Input
                                    name=''
                                    label='ИНН'
                                    value={
                                        counterparty.additionalData.documents
                                            .kpp ?? ''
                                    }
                                    disabled
                                />
                            </Flex>
                            <Input
                                name=''
                                label='Юридический адресс поставщика'
                                value={
                                    counterparty.additionalData.documents
                                        .legalAddress ?? ''
                                }
                                disabled
                            />
                            <Input
                                name=''
                                label='Основание'
                                value={basis}
                                onChange={onBasisChange}
                                placeholder='Напишите основание: Договор №1 от 28.08.2023'
                                required
                            />
                        </Flex>
                    </Accordion>

                    <Button
                        onClick={process}
                        disabled={isButtonDisabled}
                        fullWidth
                    >
                        Выставить счет
                    </Button>
                </div>
            </div>
        </DetailsPaymentsFormStyled>
    )
}
