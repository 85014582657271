import styled, { css } from 'styled-components'

const NotFullScreenStyles = css`
    display: flex;
`

const FullScreenStyles = css`
    width: 100%;
    height: 100%;
`

export const WrapperModal = styled.div<{ isFullScreen?: boolean }>`
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    ${props => (props?.isFullScreen ? FullScreenStyles : NotFullScreenStyles)};
    justify-content: center;
    align-items: center;
    z-index: 9999;
`

const NotFullScreenContentStyles = css`
    background: #fff;
    border-radius: 16px;
    width: 100%;
    max-width: 348px;
    padding: 20px;
`

const FullScreenContentStyles = css`
    background-color: #f4f3fa;
    height: 100%;
    padding: 20px 0 0 20px;
    overflow-y: auto;
`

export const WrapperModalContent = styled.div<{ isFullScreen?: boolean }>`
    ${props =>
        props?.isFullScreen
            ? FullScreenContentStyles
            : NotFullScreenContentStyles};
    &.modal-litle-size {
        max-width: 407px;
    }
    .modalcontent__btns {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        button {
            width: 100%;
        }
        &--outline {
            border: 1px solid ${({ theme }) => theme.themeColors.main};
            background: none;
            span {
                color: ${({ theme }) => theme.themeColors.main};
            }
        }
    }
    .blockWithComment {
        display: flex;
        flex-direction: column;
    }
    .modalNomenclatureSelling {
        gap: 15px;
    }
    textarea {
        margin-top: 10px;
        border-radius: 4px;
        border: 1px solid #e1e4ea;
        padding: 10px;
        height: 70px;
        color: #8181a5;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        resize: none;
    }
`

const NotFullScreenContentTopStyles = css``

const FullScreenContentTopStyles = css`
    padding-left: 20px;
    padding-right: 20px;
`

export const ModalContentTop = styled.div<{ isFullScreen?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e4ea;
    margin-bottom: 10px;
    ${props =>
        props?.isFullScreen
            ? FullScreenContentTopStyles
            : NotFullScreenContentTopStyles};
    .modal-title {
        color: #1c1c1c;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
`

export const ModalClose = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
`
