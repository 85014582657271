import styled, { css } from 'styled-components'

export const StyledTitle = styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: #282a42;
`

export const StyledFooterContainer = styled.div`
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    padding: '20px 0';
`

export const StyledItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const StyledFooterColumn = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledFooterRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledDescription = css`
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
`

export const StyledDescriptionItem = styled.span`
    ${StyledDescription};

    color: #8181a5;
    flex: 4;
`

export const StyledDescriptionPrice = styled.span<{
    isHideListPadding?: boolean
}>`
    ${StyledDescription};

    color: #282a42;
    flex: 1;
    margin: ${props => (props.isHideListPadding ? '0 0 0 -25px' : undefined)};
`

export const List = styled.ol`
    margin: 0;
    padding: 0 0 0 25px;
`
