import { DashboardLayout } from "app/layout"
import { EmployeesTable } from "features/Employees"
import { useMemo, useState } from "react"
import { Button } from "shared/components/Button"
import { Input } from "shared/components/Input"
import { TabLink } from "shared/components/TabLink"
import { listPages } from "shared/consts/linksControlPages"
import { WrapperSearch } from "./style"
import { useNavigate } from "react-router-dom"
import { axiosApi } from "shared/api/axios"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "shared/redux/slice/modalSlice"
import { showNotification } from "shared/redux/slice/notificationSlice"
import { useQueryClient } from "@tanstack/react-query"
import { EMPLOYEECREATE } from "shared/consts/routingConsts"

export const EmployeesList = () => {
    const queryClient = useQueryClient()

    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [search, setSearch] = useState<string>("");

    const handleDelete = async (selectedRows: any[]) => {
        const arraySelected = selectedRows.map((item) => item.id);
        try {
            const response = await axiosApi.delete(`/api/v1/users/user/`, {
                data: {
                    user_ids: arraySelected
                }
            });
            if (response.status === 204) {
                dispatch(showNotification({ message: 'Успешно!', type: 'success' }));
                setSelectedRows([]);
                await queryClient.refetchQueries({
                    queryKey: ['getEmployees'],
                    exact: false,
                });
                dispatch(closeModal());
            } else {
                dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
            }
        } catch (error) {
            dispatch(showNotification({ message: 'Ошибка!', type: 'error' }));
        }
    };

    const hasSelectedRows = useMemo(() => {
        return selectedRows.length > 0;
    }, [selectedRows]);

    const onSearch = (e: any) => {
        setSearch(e.target.value);
    }

    const handleClick = (to: string) => {
        navigate(to);
    }

    const clearSearch = () =>{
        setSearchActive(false);
        setSearch("");
    }

    return (
        <DashboardLayout header={
            <div className="dashboard__header">
                <div className="dashboard__header-title">Управление</div>
                <TabLink list={listPages} />
                {!hasSelectedRows ? (
                    <div className="dashboard__right-menu" style={{marginLeft: "inherit"}}>
                        {searchActive ? (
                            <WrapperSearch>
                                <Input name="" $beforeIcon="search" placeholder="Поиск" onChange={onSearch} />
                                {/* Удалю svg позже */}
                                <div className="closeSearch" onClick={clearSearch} >
                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="15" height="15" rx="7.5" fill="#8181A5"/>
                                        <path d="M10.374 10.2683C10.4139 10.3081 10.4456 10.3554 10.4672 10.4075C10.4889 10.4596 10.5 10.5155 10.5 10.5719C10.5 10.6283 10.4889 10.6841 10.4672 10.7362C10.4456 10.7883 10.4139 10.8356 10.374 10.8754C10.2931 10.9552 10.1841 11 10.0705 11C9.95693 11 9.84791 10.9552 9.76706 10.8754L7.5 8.60769L5.23294 10.8754C5.15209 10.9552 5.04307 11 4.92947 11C4.81588 11 4.70685 10.9552 4.62601 10.8754C4.58607 10.8356 4.55438 10.7883 4.53275 10.7362C4.51113 10.6841 4.5 10.6283 4.5 10.5719C4.5 10.5155 4.51113 10.4596 4.53275 10.4075C4.55438 10.3554 4.58607 10.3081 4.62601 10.2683L6.89307 8.00058L4.62601 5.73285C4.54552 5.65234 4.50031 5.54315 4.50031 5.42929C4.50031 5.37292 4.51141 5.31709 4.53298 5.26501C4.55454 5.21292 4.58616 5.1656 4.62601 5.12574C4.66586 5.08587 4.71317 5.05425 4.76524 5.03268C4.81731 5.0111 4.87311 5 4.92947 5C5.04329 5 5.15245 5.04523 5.23294 5.12574L7.5 7.39347L9.76706 5.12574C9.84755 5.04523 9.95671 5 10.0705 5C10.1843 5 10.2935 5.04523 10.374 5.12574C10.4545 5.20624 10.4997 5.31544 10.4997 5.42929C10.4997 5.54315 10.4545 5.65234 10.374 5.73285L8.10693 8.00058L10.374 10.2683Z" fill="white"/>
                                    </svg>
                                </div>
                            </WrapperSearch>
                        ) : (
                            <>
                                <Button themeType="item" beforeIcon="search" onClick={() => setSearchActive(true)} />
                                <Button requiredPermission="add" onClick={() => handleClick(EMPLOYEECREATE)}>+Сотрудник</Button>
                            </>
                        )}
                    </div>
                ) : (
                    <Button 
                        requiredPermission="add"
                        themeType="delete" 
                        onClick={() => dispatch(openModal({
                            isOpen: true,
                            content: (
                                <>
                                    Вы удалите профиль сотрудника<br/>
                                    <b>
                                        {selectedRows
                                            .map(item => {
                                            const contact = item.user_ad_personal_info;
                                            return [contact.surname, contact.first_name, contact.patronymic]
                                                .filter(Boolean)
                                                .join(' ');
                                            })
                                            .filter(Boolean)
                                            .join(', ')
                                        }
                                    </b>
                                </>
                            ),
                            config: {
                                title: "Вы точно хотите удалить?",
                                btns: [
                                    {
                                        onClick: () => handleDelete(selectedRows),
                                        title: "Да",
                                        active: true,
                                    },
                                    {
                                        onClick: () => dispatch(closeModal()),
                                        title: "Нет",
                                        active: false
                                    }
                                ]
                            }
                        }))}
                    >Удалить сотрудника</Button>
                )}
            </div>
        }>
            <EmployeesTable 
                onSelectedRowsChange={setSelectedRows}
                search={search}
                selectedRows={selectedRows}
            />
        </DashboardLayout>
    )
}