import { useGetRolesSingleQuery } from 'entities/roles/model/hooks'
import { IPropsRolesSingle } from 'entities/roles/types'
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react'

interface IPropsPermissionsProviders {
    children: ReactNode
}

interface IPermissionsContext {
    permissions: IPropsRolesSingle | null
}

const PermissionsContext = createContext<IPermissionsContext>({
    permissions: null,
})

export const usePermissionsContext = () => useContext(PermissionsContext)

export const PermissionsProviders = ({
    children,
}: IPropsPermissionsProviders) => {
    const [permissions, setPermissions] = useState<IPropsRolesSingle | null>(null)

    const user = JSON.parse(localStorage.getItem('users') as string)
    const userRole = user?.role?.id ? Number(user?.role?.id) : null

    const { roles, isSuccess } = useGetRolesSingleQuery(userRole)

    const memoizedPermissions = useMemo(() => {
        if (roles.id && isSuccess) {
            localStorage.setItem('permissionsUsers', JSON.stringify(roles.permissions))
            return roles
        }
        return null
    }, [roles, isSuccess])

    useEffect(() => {
        if (memoizedPermissions) {
            setPermissions(memoizedPermissions)
        }
    }, [memoizedPermissions])

    return (
        <PermissionsContext.Provider value={{ permissions }}>
            {children}
        </PermissionsContext.Provider>
    )
}
