import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { QueryKeys } from "./constants";
import { RolesApi } from "entities/roles/api/api";
import { IPropsRolesSingle } from "entities/roles/types";

export const useGetRolesSingleQuery = (
    id: number | null,
    options?: UseQueryOptions
) => {
    const { 
        data, 
        error, 
        isError,
        isLoading, 
        isSuccess, 
        refetch, 
        status 
    } = useQuery({
        initialData: [],
        queryKey: [QueryKeys.GetRolesSingle, id],
        queryFn: id ? () => RolesApi.getRolesSingle(id) : () => null,
        ...(options ?? {}),
    })

    return {
        roles: data as IPropsRolesSingle,
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}