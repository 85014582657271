import { AttachmentsEndpoint } from 'entities/orderAttachments/api/constants'
import { DocumentDTO } from 'entities/orderAttachments/model/dtoTypes'
import { adaptDocumentDTO } from 'entities/orderAttachments/model/adapters/adaptDocumentDTO'
import { axiosApi } from 'shared/api/axios'

export const getAllDocuments = async (orderId: number, paymentId?: number | null) => {
    try {
        const response = await axiosApi.get<DocumentDTO[]>(
            `${AttachmentsEndpoint.order}/${orderId}`,
            {
                params: {
                    payment_id: paymentId,
                }
            }
        )
        return response.data.map(adaptDocumentDTO)
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка получение документов')
    }
}
