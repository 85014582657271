import { useSelector } from 'react-redux'
import { useMemo, useState } from 'react'

import {
    selectBookingOriginalOrder,
    selectIsCompanyBookingForm,
} from 'features/Booking/model/slices'
import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'
import {
    PaymentMethodLabels,
    PaymentMethodName,
    RefundBooking,
    RefundPaymentRequest,
} from 'entities/payments/model'
import { Nomenclature, OrderBooking } from 'entities/orders/model'
import { PaymentApi } from 'entities/payments/api'
import { AutocompleteOption } from 'shared/components/Autocomplete'

export const refundWayOptions: AutocompleteOption[] = [
    {
        label: PaymentMethodLabels.CASH,
        value: PaymentMethodName.Cash,
    },
    {
        label: PaymentMethodLabels.ONLINE,
        value: PaymentMethodName.Online,
    },
    {
        label: PaymentMethodLabels.CERTIFICATE,
        value: PaymentMethodName.Certificate,
    },
]

export const companyRefundWayOptions: AutocompleteOption[] = [
    ...refundWayOptions,
    {
        label: PaymentMethodLabels.ACCOUNT,
        value: PaymentMethodName.Account,
    },
]

type RefundBookingState = Omit<RefundPaymentRequest, 'type'>

export const useOrderRefundController = () => {
    const originalOrder = useSelector(selectBookingOriginalOrder)
    const [bookingsToRefund, setBookingsToRefund] =
        useState<RefundBookingState>({ bookings: [] })
    const [currentRefundWay, setCurrentRefundWay] =
        useState<AutocompleteOption | null>(null)
    const isCompany = useSelector(selectIsCompanyBookingForm)

    const onChangeBookingsToRefund = (
        booking: OrderBooking,
        nomenclature?: Nomenclature,
    ) => {
        const existBookingToRefund = bookingsToRefund?.bookings?.find(
            bkg => bkg.id === booking.id,
        )

        if (existBookingToRefund && !nomenclature) {
            const newBookingsToRefund = {
                bookings: bookingsToRefund?.bookings?.filter(
                    bkg => bkg.id !== existBookingToRefund.id,
                ),
            }
            setBookingsToRefund(newBookingsToRefund)
            return
        }

        if (existBookingToRefund && nomenclature) {
            const nomenclatureExist = bookingsToRefund?.bookings?.find(
                bkg =>
                    !!bkg.nomenclatures?.find(
                        nom => nom.id === nomenclature.id,
                    ),
            )

            if (nomenclatureExist) {
                if (nomenclatureExist.area.count > 0) {
                    const newBookingsToRefund = {
                        bookings: bookingsToRefund?.bookings?.filter(
                            bkg => bkg.id !== existBookingToRefund.id,
                        ),
                    }
                    setBookingsToRefund(newBookingsToRefund)
                    return
                }

                const newBookingsToRefund = {
                    bookings: bookingsToRefund?.bookings?.map(bkg => ({
                        ...bkg,
                        nomenclatures: bkg.nomenclatures.filter(
                            nom => nom.id !== nomenclature.id,
                        ),
                    })),
                }

                const newNomenclatureExist =
                    newBookingsToRefund?.bookings?.find(
                        bkg => bkg.id === booking.id,
                    )

                if (
                    newNomenclatureExist?.area.count === 0 &&
                    newNomenclatureExist.nomenclatures.length === 0
                ) {
                    const newBookingsToRefund = {
                        bookings: bookingsToRefund?.bookings?.filter(
                            bkg => bkg.id !== booking.id,
                        ),
                    }
                    setBookingsToRefund(newBookingsToRefund)
                    return
                }
                setBookingsToRefund(newBookingsToRefund)
                return
            }

            const newBookingsToRefund = {
                bookings: bookingsToRefund?.bookings?.map(bkg => ({
                    ...bkg,
                    nomenclatures: [
                        ...bkg.nomenclatures,
                        {
                            id: nomenclature.id,
                            count: 1,
                        },
                    ],
                })),
            }

            setBookingsToRefund(newBookingsToRefund)
            return
        }

        const nomenclatureToRefund = nomenclature
            ? [
                  {
                      id: nomenclature.id,
                      count: 1,
                  },
              ]
            : null

        const bookingToRefund: RefundBooking = {
            area: {
                count: nomenclature ? 0 : getBookingRange(booking!).diff,
            },
            id: booking.id,
            nomenclatures:
                nomenclatureToRefund ??
                (booking?.nomenclatures ?? [])
                    .filter(nomenclature => nomenclature.count > 0)
                    .map(nomenclature => ({
                        id: nomenclature.id,
                        count: nomenclature.count,
                    })),
        }

        const newBookingsToRefund = {
            bookings: [...(bookingsToRefund?.bookings ?? []), bookingToRefund],
        }

        setBookingsToRefund(newBookingsToRefund)
    }

    const onChangeAreaCount = (booking: OrderBooking, newCount: number) => {
        if (!originalOrder) {
            return
        }
        const areaCount = getBookingRange(booking!).diff
        const newAreaCount =
            newCount > areaCount ? areaCount : Math.max(newCount, 0)

        if (newAreaCount === 0) {
            const existBookingToRefund = bookingsToRefund?.bookings?.find(
                bkg => bkg.id === booking.id,
            )

            if (
                existBookingToRefund &&
                existBookingToRefund.nomenclatures.length == 0
            ) {
                const newBookingsToRefund = {
                    bookings: bookingsToRefund?.bookings?.filter(
                        bkg => bkg.id !== existBookingToRefund.id,
                    ),
                }
                setBookingsToRefund(newBookingsToRefund)
                return
            }
        }

        const newBookingsToRefund = {
            bookings: bookingsToRefund?.bookings?.map(bkg =>
                bkg.id === booking.id
                    ? {
                          ...bkg,
                          area: {
                              ...bkg.area,
                              count: newAreaCount,
                          },
                      }
                    : bkg,
            ),
        }
        setBookingsToRefund(newBookingsToRefund)
    }

    const getAreaCount = (booking: OrderBooking) => {
        return (
            bookingsToRefund?.bookings?.find(bkg => bkg.id === booking.id)?.area
                .count ?? 0
        )
    }

    const onChangeNomenclaturesCount = (
        nomenclature: Nomenclature,
        newCount: number,
    ) => {
        let nomenclaturesCount = 0
        originalOrder?.bookings?.forEach(bkg => {
            nomenclaturesCount =
                bkg.nomenclatures.find(nom => nom.id === nomenclature.id)
                    ?.count ?? 0
        })

        const newNomenclaturesCount =
            newCount > nomenclaturesCount
                ? nomenclaturesCount
                : Math.max(newCount, 0)

        if (newNomenclaturesCount === 0) {
            const newBookingsToRefund = {
                bookings: bookingsToRefund?.bookings?.map(bkg => ({
                    ...bkg,
                    nomenclatures: bkg.nomenclatures.filter(
                        nom => nom.id !== nomenclature.id,
                    ),
                })),
            }

            setBookingsToRefund(newBookingsToRefund)
            return
        }

        const newBookingsToRefund = {
            bookings: bookingsToRefund?.bookings?.map(bkg => ({
                ...bkg,
                nomenclatures: bkg.nomenclatures.map(nom =>
                    nom.id === nomenclature.id
                        ? {
                              ...nom,
                              count: newNomenclaturesCount,
                          }
                        : nom,
                ),
            })),
        }
        setBookingsToRefund(newBookingsToRefund)
    }

    const getNomenclaturesCount = (
        booking: OrderBooking,
        nomenclature: Nomenclature,
    ) => {
        const bookingToRefund = bookingsToRefund?.bookings?.find(
            bkg => bkg.id === booking.id,
        )

        if (!bookingToRefund) {
            return 0
        }

        return (
            bookingToRefund.nomenclatures.find(
                nom => nomenclature.id === nom.id,
            )?.count ?? 0
        )
    }

    const isBookingChecked = (booking: OrderBooking) => {
        const ifExist = bookingsToRefund?.bookings?.find(
            bkg => bkg.id === booking.id,
        )
        return !!ifExist && ifExist.area.count > 0
    }

    const isNomenclatureChecked = (nomenclature: Nomenclature) => {
        const ifExist = bookingsToRefund?.bookings?.find(
            bkg => !!bkg.nomenclatures?.find(nom => nom.id === nomenclature.id),
        )
        return !!ifExist
    }

    const refund = async () => {
        if (!originalOrder?.id || !currentRefundWay?.value) {
            return
        }
        const fullBookingsToRefund = {
            ...bookingsToRefund,
            type: currentRefundWay.value as PaymentMethodName,
        }
        await PaymentApi.refundOrder(originalOrder.id, fullBookingsToRefund)
    }

    const fullRefund = async () => {
        if (!originalOrder?.id || !currentRefundWay?.value) {
            return
        }
        const allBookingsToRefund: RefundBooking[] =
            originalOrder?.bookings?.map(booking => ({
                area: {
                    count: getBookingRange(booking!).diff,
                },
                id: booking.id,
                nomenclatures: (booking?.nomenclatures ?? [])
                    .filter(nomenclature => nomenclature.count > 0)
                    .map(nomenclature => ({
                        id: nomenclature.id,
                        count: nomenclature.count,
                    })),
            }))

        const fullBookingsToRefund = {
            bookings: allBookingsToRefund,
            type: currentRefundWay.value as PaymentMethodName,
        }
        await PaymentApi.refundOrder(originalOrder.id, fullBookingsToRefund)
    }

    const resultToRefund = useMemo(() => {
        let total = 0
        bookingsToRefund.bookings.forEach(booking => {
            const orderBooking = originalOrder?.bookings?.find(
                bkg => bkg.id === booking.id,
            )

            if (!orderBooking) {
                return
            }

            const areaPrice = orderBooking.areaPrice ?? 0
            total +=
                areaPrice * booking.area.count -
                (orderBooking?.areaAbsolutDiscount ?? 0)

            booking.nomenclatures.forEach(nomenclature => {
                const orderNomenclature = orderBooking.nomenclatures.find(
                    nom => nom.id === nomenclature.id,
                )

                if (!orderNomenclature) {
                    return
                }

                const nomenclaturePrice = orderNomenclature.price ?? 0
                total +=
                    nomenclaturePrice * nomenclature.count -
                    (orderNomenclature?.absolutDiscount ?? 0)
            })
        })
        return total
    }, [bookingsToRefund, originalOrder])

    return {
        isCompany,
        bookingsToRefund,
        originalOrder,
        resultToRefund,
        currentRefundWay,

        setCurrentRefundWay,
        onChangeAreaCount,
        getAreaCount,
        onChangeNomenclaturesCount,
        getNomenclaturesCount,
        isBookingChecked,
        isNomenclatureChecked,
        refund,
        fullRefund,
        onChangeBookingsToRefund,
    }
}
