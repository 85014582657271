import { FC } from 'react'
import { StyledAreaSelect } from './styles'
import { InputContainer, Label } from 'shared/components/Input/style'

export type SelectOption = { value: number | string; title: string }

export interface SelectProps {
    onChange: (optionId: number | string) => void
    value?: number | string | null
    options: SelectOption[]
    placeholder: string
    className?: string
    label?: string
    required?: boolean
    disabled?: boolean
    name?: string;
}

export const Select: FC<SelectProps> = ({
    onChange,
    value = "",
    options,
    placeholder,
    className,
    label,
    required,
    disabled,
    name
}) => (
    <InputContainer className={className}>
        {label && (
            <Label>
                {required && <span className='required'>*</span>}
                {label}
            </Label>
        )}
        <StyledAreaSelect
            onChange={e => onChange(e.target.value)}
            value={value ?? ''}
            disabled={disabled}
            name={name}
        >
            <option value=''>{placeholder}</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>{option.title}</option>
            ))}
        </StyledAreaSelect>
    </InputContainer>
)
