import { DetailOrderDTO } from '../dtoTypes'
import { DetailOrder } from '../types'
import { adaptOrderDTO } from './adaptOrderDTO'
import { adaptBookingDTO } from './adaptBookingDTO'

export const adaptDetailOrderDTO = ({
    bookings,
    ...order
}: DetailOrderDTO): DetailOrder => ({
    ...adaptOrderDTO(order),
    bookings: bookings?.map(adaptBookingDTO) ?? [],
    createdAt: order.created_at,
})
