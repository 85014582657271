import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getRolesSingle } from "../api/getRoles";

export const useGetRolesSingle = (id: number) => {
    const [draft, setDraft] = useState(undefined);
    const { data, ...queryInfo } = useQuery({
        queryKey: ["getRolesSingle", id], 
        queryFn: () => {
            return getRolesSingle(id);
        },
        enabled: typeof draft === "undefined"
    });

    return {
        value: draft ?? data,
        setDraft,
        queryInfo
    }
}