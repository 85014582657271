import { DashboardLayout } from "app/layout"
import { SettingSidebarStyled, SettingWrapper } from "./style"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"
import { sidebardMenuSetting } from "shared/consts/sidebarMenuData"
import { Icon } from "shared/components/Icons"
import { useEffect } from "react"
import { SETTINGPROFILE } from "shared/consts/routingConsts"

export const SettingPages = () => {

    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/setting') {
            navigate(SETTINGPROFILE)
        }
    }, [])

    return (
        <DashboardLayout>
            <SettingWrapper>
                <SettingSidebarStyled>
                    {sidebardMenuSetting.map((item, index) => (
                        <NavLink key={index} to={item.link}>
                            <Icon name={item.icon} />
                            {item.title}
                        </NavLink>
                    ))}
                </SettingSidebarStyled>
                <Outlet />
            </SettingWrapper>
        </DashboardLayout>
    )
}