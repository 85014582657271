import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CounterpartiesApi } from '../../api'
import { QueryKeys } from './constants'
import {
    Counterparty,
    defaultOptions,
    UseCounterpartyAsyncAutocompleteOptionsParams,
} from '../index'

export const useCounterpartiesQuery = (
    queryOptions?: UseCounterpartyAsyncAutocompleteOptionsParams,
    options?: UseQueryOptions,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            queryKey: [QueryKeys.GetAllCounterpartiesQuery],
            queryFn: () =>
                CounterpartiesApi.getCounterparties({
                    ...defaultOptions,
                    ...queryOptions,
                }),
            ...(options ?? {}),
        })

    return {
        counterparties: data as Counterparty[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
