import { FC } from 'react'

import { OrderPaidStatus } from 'features/OrderPreview/ui/components/OrderPaidStatus'
import { DetailOrder } from 'entities/orders/model'

import {
    StyledFooterContainer,
    StyledItemsContainer,
    List,
    StyledFooterRow,
    StyledDescriptionItem,
    StyledDescriptionPrice,
} from './styles'
import { Flex } from 'shared/components/Flex'
import { BookingTotalPrice } from './BookingTotalPrice'

interface OrderTotalPricePreviewProps {
    detailOrder: DetailOrder
    hideStatus?: boolean
}

export const OrderTotalPricePreview: FC<OrderTotalPricePreviewProps> = ({
    detailOrder,
    hideStatus,
}) => (
    <StyledFooterContainer>
        <StyledItemsContainer>
            <List>
                {detailOrder.bookings.map((booking, index) => (
                    <BookingTotalPrice
                        booking={booking}
                        key={index}
                        isOrderItemMode
                    />
                ))}

                <StyledFooterRow>
                    <StyledDescriptionItem>
                        Скидка клиента:
                    </StyledDescriptionItem>
                    <StyledDescriptionPrice>{detailOrder.counterpartyDiscount}%</StyledDescriptionPrice>
                </StyledFooterRow>
            </List>

            {!hideStatus && (
                <Flex justifyContent='space-between' gap={10}>
                    <OrderPaidStatus
                        total={detailOrder.totalPriceWithDiscount}
                        paid={detailOrder.totalAmountPaid}
                    />
                </Flex>
            )}
        </StyledItemsContainer>
    </StyledFooterContainer>
)
