import { axiosApi } from "shared/api/axios";

export interface ApiResponse {
    status: number;
    data?: any;
    error?: string;
}

export const patchProfile = async (
    values: object,
    id?: number
): Promise<ApiResponse> => {
    try {
        const response = await axiosApi.patch(`/api/v1/users/user/${id}`, values);
        return {
            status: response.status,
            data: response.data,
        };
    } catch (error: any) {
        console.error("Ошибка сохранения данных:", error);
        return {
            status: error.response?.status || 500,
            error: error.message,
        };
    }
}