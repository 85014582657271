import { FC } from 'react'
import { Nomenclature } from 'entities/orders/model'
import { NomenclatureOldPriceLabel } from 'entities/bookings/ui/styles'

interface NomenclatureListPreviewItemProps {
    nomenclature: Nomenclature
}

export const NomenclatureListPreviewItem: FC<
    NomenclatureListPreviewItemProps
> = ({ nomenclature }) => (
    <>
        {nomenclature.name}{' '}
        {nomenclature.price && nomenclature.absolutDiscount > 0 ? (
            <>
                <NomenclatureOldPriceLabel>
                    ₽{nomenclature.price}
                </NomenclatureOldPriceLabel>{' '}
                ₽{nomenclature.price - nomenclature.absolutDiscount}
            </>
        ) : (
            <>₽{nomenclature.price}</>
        )}{' '}
        ({nomenclature.count}шт)
    </>
)
