import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
    AccountDetails,
    onPaymentProcess,
} from 'features/DetailsPayments/ui/DetailsPaymentsModal'
import { useGetPaymentAccountsQuery } from 'entities/paymentAccount/model'
import {
    useGetOrganisationByIdQuery,
    useGetOrganisationsQuery,
} from 'entities/organisations/model'
import { AutocompleteOption } from 'shared/components/Autocomplete'
import { closeModal } from 'shared/redux/slice/modalSlice'

export const useDetailsPaymentsForm = (
    onPaymentProcess: onPaymentProcess,
    details?: AccountDetails,
) => {
    const dispatch = useDispatch()
    const [basis, setBasis] = useState<string>()

    const { organisations } = useGetOrganisationsQuery()
    const [organisationOption, setOrganisationOption] =
        useState<AutocompleteOption>()

    const organisationsOptions: AutocompleteOption[] = organisations.map(
        organisation => ({
            value: organisation.id.toString(),
            label: organisation.inn,
        }),
    )
    // TODO: return after backend fix
    // const currentOrganisation = organisations.find(
    //     organisation =>
    //         organisation.id.toString() === organisationOption?.value,
    // )
    const onCurrentOrganisationChange = (option: AutocompleteOption) => {
        setOrganisationOption(option ? option : undefined)
    }

    const initOrganisationId = details?.organisation?.id ?? null
    const organisationId = organisationOption?.value
        ? Number(organisationOption?.value)
        : initOrganisationId
    const { paymentAccounts } = useGetPaymentAccountsQuery(organisationId)

    const [paymentAccountsOption, setPaymentAccountsOption] =
        useState<AutocompleteOption>()

    const paymentAccountsOptions: AutocompleteOption[] = paymentAccounts.map(
        paymentAccount => ({
            value: paymentAccount.id.toString(),
            label: paymentAccount.bik,
        }),
    )

    const currentPaymentAccount = paymentAccounts.find(
        paymentAccount =>
            paymentAccount.id.toString() === paymentAccountsOption?.value,
    )
    const onCurrentPaymentAccountChange = (option: AutocompleteOption) => {
        setPaymentAccountsOption(option)
    }

    const onBasisChange = (e: FormEvent) => {
        setBasis((e.target as HTMLInputElement).value)
    }

    const { organisation: currentOrganisation } = useGetOrganisationByIdQuery(organisationId)

    const process = () => {
        if (
            !currentOrganisation ||
            !currentPaymentAccount ||
            !basis ||
            !basis?.trim()?.length
        ) {
            return
        }

        onPaymentProcess({
            organisation: currentOrganisation,
            paymentAccount: {
                id: currentPaymentAccount.id,
                bik: currentPaymentAccount.bik,
                number: currentPaymentAccount.number,
                correspondentNumber: currentPaymentAccount.correspondentNumber,
                bankName: currentPaymentAccount.bankName,
            },
            basis,
        })
        dispatch(closeModal())
    }

    const isButtonDisabled = useMemo(
        () =>
            !organisationOption?.value ||
            !paymentAccountsOption?.value ||
            !basis ||
            !basis?.trim()?.length,
        [organisationOption, paymentAccountsOption, basis],
    )

    useEffect(() => {
        if (initOrganisationId) {
            const initOrganisation = organisationsOptions.find(
                organisation =>
                    organisation.value === initOrganisationId.toString(),
            )
            if (!initOrganisation) {
                return
            }
            setOrganisationOption(initOrganisation)
        }
    }, [])

    const initPaymentAccountId = details?.paymentAccount?.id ?? null
    useEffect(() => {
        if (initPaymentAccountId) {
            const initPaymentAccount = paymentAccountsOptions.find(
                paymentAccount =>
                    paymentAccount.value === initPaymentAccountId?.toString(),
            )
            if (!initPaymentAccount) {
                return
            }
            setPaymentAccountsOption(initPaymentAccount)
        }
    }, [])

    const initBasement = details?.basis ?? null
    useEffect(() => {
        if (initBasement) {
            setBasis(initBasement)
        }
    }, [])

    return {
        currentOrganisation,
        organisationsOptions,
        organisationOption,
        onCurrentOrganisationChange,

        currentPaymentAccount,
        paymentAccountsOptions,
        paymentAccountsOption,
        onCurrentPaymentAccountChange,

        basis,
        onBasisChange,

        isButtonDisabled,
        process,
    }
}
