import { CreateAccountPaymentRequestDTO } from 'entities/payments/model/dtoTypes'
import { CreateAccountPaymentRequest } from 'entities/payments/model/types'

export const adaptCreateAccountPaymentRequest = (
    body: CreateAccountPaymentRequest,
): CreateAccountPaymentRequestDTO => ({
    order_id: body.orderId,
    organization: {
        id: body.organisation.id,
        name: body.organisation.name,
        inn: body.organisation.inn,
        kpp: body.organisation.kpp,
        legal_address: body.organisation.legalAddress,
        ceo: body.organisation.ceo,
    },
    payment_account: {
        id: body.paymentAccount.id,
        bik: body.paymentAccount.bik,
        number: body.paymentAccount.number,
        correspondent_number: body.paymentAccount.correspondentNumber,
        bank_name: body.paymentAccount.bankName,
    },
    amount: body.amount,
    basis: body.basis,
    comment: body?.comment,
})
