export const CLIENTSLIST = "/clients";
export const CLIENTSPROFILE = `${CLIENTSLIST}/profile`;
export const CLIENTSCREATE = `${CLIENTSLIST}/create`;

export const SETTING = "/setting";
export const SETTINGPROFILE = `${SETTING}/profile`;
export const SETTINGTHEME = `${SETTING}/theme`;
export const SETTINGNOTIFICATION = `${SETTING}/notification`;
export const SETTINGBOOKINGS = `${SETTING}/bookings`;
export const SETTINGROLE = `${SETTING}/role`;

export const SETTINGORG = `${SETTING}/org`;
export const SETTINGORGCREATE = `${SETTING}/org/create`;
export const SETTINGORGPROFILE = `${SETTING}/org/profile`;

export const CALENDAR = "/calendar"
export const CALENDARDAY = `${CALENDAR}/day`;
export const CALENDARWEEK = `${CALENDAR}/week`;
export const CALENDARMONTH = `${CALENDAR}/month`;



export const MANAGEMENT = '/management';

export const AREASLIST = `${MANAGEMENT}/areas`;
export const AREASPROFILE = `${AREASLIST}/profile`;
export const AREASCREATE = `${AREASLIST}/create`;

export const NOMENCLATURELIST = `${MANAGEMENT}/nomenclature`;
export const NOMENCLATUREPROFILE = `${NOMENCLATURELIST}/profile`;
export const NOMENCLATURECREATE = `${NOMENCLATURELIST}/create`;

export const EMPLOYEESLIST = `${MANAGEMENT}/employees`;
export const EMPLOYEESPROFILE = `${EMPLOYEESLIST}/profile`;
export const EMPLOYEECREATE = `${EMPLOYEESLIST}/create/`;