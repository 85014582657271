import { SettingThemeList, SettingThemeWrapper } from "./style"
import { Icon } from "shared/components/Icons";
import { useThemeContext } from "app/providers/providers";
import { HexColorPicker } from "react-colorful";
import { Menu, MenuButton, MenuItems } from '@headlessui/react'

const themeColorList = ["#5E81F4", "#FFD400", "#FF4747", "#7856FF", "#FF7A00", "#00BA7C"];

export const SettingTheme = () => {
    const { theme, setThemeColor } = useThemeContext();

    const handleThemeColorChange = (color: string) => {
        setThemeColor(color);
    };


    return (
        <SettingThemeWrapper>
            <div className="title">Основной цвет</div>
            <SettingThemeList>
                {themeColorList.map((item, index) => (
                    <div onClick={() => handleThemeColorChange(item)} key={index} className="block" style={{backgroundColor: item}}>
                        {item === theme.themeColors.main && <Icon name="checkTwo" />}
                    </div>
                ))}
                <Menu>
                    <MenuButton className="menuButton">
                        <div className="block blockOther">
                            {themeColorList.filter(item => item !== theme.themeColors.main) && <Icon name="checkTwo" />}
                        </div>
                    </MenuButton>
                    <MenuItems className="openColorPicker" as="div" anchor="bottom">
                        <HexColorPicker color={theme.themeColors.main} onChange={handleThemeColorChange} />
                    </MenuItems>
                </Menu>
            </SettingThemeList>
        </SettingThemeWrapper>
    )
}