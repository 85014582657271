import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { CardBooking } from 'features/RightSidebar/components/CardBooking'
import { useGetAheadBookingsQuery } from 'entities/bookings/model'
import { Icon } from 'shared/components/Icons'
import { RootState } from 'shared/redux/store'

import { useSidebar } from './components/SidebarProvider/SidebarProvider'
import { CardNotifications } from './components/CardNotifications'
import { BookingSidebar } from '../Booking/ui/BookingSidebar'
import { Box, StyledBookingForm, StyledItem, StyledSidebar } from './style'

export const RightSidebar: React.FC = () => {
    const { isOpen, component } = useSelector(
        (state: RootState) => state.sidebar,
    )

    const [aheadBookingIdToPreview, setAheadBookingIdToPreview] = useState<
        number | null
    >(null)
    const { isExpanded, toggleSidebar } = useSidebar()

    const { aheadBookings, isLoading } = useGetAheadBookingsQuery()

    if (isOpen && component === 'BookingComponent') {
        return <BookingSidebar />
    }

    if (aheadBookingIdToPreview) {
        return (
            <Box
                isExpanded={isExpanded}
                isFullHeight={!!aheadBookingIdToPreview}
            >
                <div className='sibedar-top-row'>
                    <StyledItem
                        onClick={() => setAheadBookingIdToPreview(null)}
                    >
                        <button>
                            <Icon
                                className='back'
                                name='arrowRight'
                                style={{
                                    transform: isExpanded
                                        ? 'rotate(180deg)'
                                        : 'rotate(0deg)',
                                }}
                            />
                        </button>
                    </StyledItem>
                    <div className='line'></div>
                    <StyledBookingForm>
                        <CardBooking id={aheadBookingIdToPreview} />
                    </StyledBookingForm>
                </div>
            </Box>
        )
    }

    return (
        <Box isExpanded={isExpanded}>
            <div className='sibedar-top-row'>
                <StyledItem onClick={toggleSidebar}>
                    <button>
                        <Icon
                            className='back'
                            name='arrowRight'
                            style={{
                                transform: isExpanded
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                            }}
                        />
                    </button>
                </StyledItem>
                <div className='line'></div>
            </div>
            {isExpanded && (
                <StyledSidebar>
                    <div className='title'>
                        Уведомления
                        <div className='badge'>
                            {aheadBookings?.length ?? 0}
                        </div>
                    </div>
                    {!isLoading && aheadBookings && (
                        <>
                            {aheadBookings.map(booking => (
                                <CardNotifications
                                    key={booking.id}
                                    setAheadBookingIdToPreview={
                                        setAheadBookingIdToPreview
                                    }
                                    aheadBookings={booking}
                                />
                            ))}
                        </>
                    )}
                </StyledSidebar>
            )}
            {!isExpanded && (
                <>
                    <StyledItem onClick={toggleSidebar}>
                        <button>
                            {aheadBookings.length !== 0 && 
                                <div className='badge'>
                                    {aheadBookings?.length}
                                </div>
                            }
                            <Icon name='notification' />
                        </button>
                    </StyledItem>
                </>
            )}
        </Box>
    )
}
