import { FC } from 'react'
import moment from 'moment'

import { AheadBooking } from 'entities/bookings/model'
import NotificationsUser from 'shared/components/Icons/IconsList/rightSidebar/notificationsUser'

import {
    StyledNotifications,
    StyledNotificationsContent,
    StyledNotificationsIcon,
    StyledNotificationsWrapper,
} from './style'

interface CardNotificationsProps {
    aheadBookings: AheadBooking
    setAheadBookingIdToPreview: (id: number) => void
}

export const CardNotifications: FC<CardNotificationsProps> = ({
    aheadBookings,
    setAheadBookingIdToPreview,
}) => (
    <StyledNotifications
        onClick={() => setAheadBookingIdToPreview(aheadBookings.id)}
    >
        <StyledNotificationsWrapper>
            <StyledNotificationsIcon>
                <NotificationsUser />
            </StyledNotificationsIcon>
            <StyledNotificationsContent>
                <div className='title-card'>Новое бронирование</div>
                <div className='notifications-text time'>
                    {moment(aheadBookings.startTime).format('DD.MM.YYYY HH:MM')}{' '}
                    - {moment(aheadBookings.endTime).format('DD.MM.YYYY HH:MM')}
                </div>
                <div className='notifications-text information'>
                    {aheadBookings.areaName}, {aheadBookings.counterpartyName},{' '}
                    <br />
                    {aheadBookings.counterpartyPhone}
                </div>
                <div className='notifications-text comments'>
                    {aheadBookings.comment}
                </div>
                <div className='notifications-text status'>
                    {aheadBookings.status === 'pending' && 'В обработке'}
                    {aheadBookings.status === 'paid' && 'Оплачено'}
                    {aheadBookings.status === 'completed' && 'Завершено'}
                    {aheadBookings.status === 'cancelled' && 'Отменено'}
                    {aheadBookings.status === 'refund' && 'Возврат'}
                </div>
                <div className='notifications-text payment'>
                    {aheadBookings.totalPrice}
                </div>
            </StyledNotificationsContent>
        </StyledNotificationsWrapper>
    </StyledNotifications>
)
