
import { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { CALENDARDAY } from "shared/consts/routingConsts"

export const CalendarPages = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/calendar') {
            navigate(CALENDARDAY)
        }
    }, [])


    return (
        <Outlet />
    )
}