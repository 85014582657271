
import { useEffect, useState } from "react";
import { FormikInput } from "../FormikInput";
import { Select } from "../Select";
import { WrapperFormRowStyled } from "./style"
import { useFormikContext } from "formik";

interface IPropsData {
    value?: string | number;
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    type: 'text' | 'email' | 'password' | 'number' | string; 
    required?: boolean;
    maskConfig?: any;
    component?: React.ComponentType<any>;
}

interface IPropsSelectValues {
    name: string;
    id: number;
}

interface IPropsWrapperFormRow {
    title?: string;
    formData: Array<Array<IPropsData>>;
    children?: React.ReactNode;
    selectValues?: IPropsSelectValues[];
    roleID?: number | null;
}

export const WrapperFormRow = ({
    title,
    formData,
    children,
    selectValues,
    roleID = null
}: IPropsWrapperFormRow) => {
    const [roleCustomID, setRoleCustomID] = useState<number | null>(roleID);

    const { setFieldValue, values } = useFormikContext<any>();

    useEffect(() => {
        if(Number(values.role)) {
            setRoleCustomID(null)
        }
    }, [values])

    return (
        <WrapperFormRowStyled>
            {title && <div className="title">{title}</div>}
            <div className="container">
                {formData.map((dataRow, index) => (
                    <div className="row" key={index}>
                        {dataRow.map((item, itemIndex) => {
                            if (item.type === 'select' && !item.disabled) {
                                return selectValues && (
                                    <Select
                                        name={item.name} 
                                        label="Роль"
                                        key={itemIndex}
                                        onChange={(value) => setFieldValue(item.name, value)}
                                        value={roleCustomID !== null ? roleCustomID : values[item.name]}
                                        required
                                        placeholder={item.placeholder || "Выберите"}
                                        options={selectValues?.map((item) => ({
                                            title: item.name,
                                            value: item.id,
                                        }))}
                                    />
                                );
                            }
                            return (
                                <FormikInput
                                    required={item.required}
                                    key={itemIndex}
                                    name={item.name}
                                    label={item.label}
                                    placeholder={item.placeholder}
                                    type={item.type}
                                    disabled={item.disabled ?? false}
                                    value={item.value ?? ""}
                                    maskConfig={item.maskConfig}
                                    className="inputForm"
                                    $afterIcon="clearInput"
                                />
                            );
                        })}
                    </div>
                ))}
                {children}
            </div>
        </WrapperFormRowStyled>
    );
};
