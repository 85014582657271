import { FC } from 'react'

import { NomenclatureListPreviewItem } from 'features/OrderPreview/ui/components/NomenclatureListPreviewItem'
import { NomenclatureListPreviewPrice } from 'features/OrderPreview/ui/components/NomenclatureListPreviewPrice'
import { getBookingRange } from 'features/OrderPreview/ui/OrderTotalPricePreview/utils'

import { OrderBooking } from 'entities/orders/model'
import { roundNumber } from 'shared/utils/roundNumber'

import {
    List,
    StyledDescriptionItem,
    StyledDescriptionPrice,
    StyledFooterColumn,
    StyledFooterContainer,
    StyledFooterRow,
    StyledItemsContainer,
    StyledTitle,
} from './styles'
import { AreaListPreviewItem } from 'features/OrderPreview/ui/components/AreaListPreviewItem'

interface BookingTotalPricePreviewProps {
    totalPrice: number
    booking: OrderBooking
    counterpartyDiscount: number
}

export const BookingTotalPricePreview: FC<BookingTotalPricePreviewProps> = ({
    totalPrice,
    booking,
    counterpartyDiscount,
}) => {
    if (!booking?.totalPriceWithDiscount && !booking?.areaPrice) {
        return null
    }

    const areaPrice = roundNumber(booking?.areaPrice)
    const areaAbsolutDiscount = roundNumber(booking?.areaAbsolutDiscount)
    const totalAreaPrice = roundNumber(booking.areaTotalPriceWithDiscount ?? 0)
    const nomenclaturePrice = roundNumber(
        booking?.nomenclaturePriceWithDiscount,
    )

    const bookingRange = getBookingRange(booking)

    return (
        <StyledFooterContainer>
            <StyledTitle>Итого: ₽{totalPrice}</StyledTitle>

            <StyledItemsContainer>
                <StyledFooterColumn>
                    <StyledFooterRow>
                        <AreaListPreviewItem
                            areaPrice={areaPrice ?? 0}
                            areaDiscount={areaAbsolutDiscount ?? 0}
                            range={bookingRange.label}
                            areaName={booking?.area?.name ?? ''}
                        />
                        <StyledDescriptionPrice>
                            <StyledDescriptionItem>
                                ₽{totalAreaPrice}
                            </StyledDescriptionItem>
                        </StyledDescriptionPrice>
                    </StyledFooterRow>
                    <StyledFooterRow>
                        <StyledDescriptionItem>
                            Дополнительно:
                        </StyledDescriptionItem>
                        <StyledDescriptionPrice>
                            ₽{nomenclaturePrice}
                        </StyledDescriptionPrice>
                    </StyledFooterRow>
                    <List>
                        {Boolean(booking?.nomenclatures?.length) && (
                            <>
                                {booking.nomenclatures.map(nomenclature => (
                                    <StyledFooterRow key={nomenclature.id}>
                                        <StyledDescriptionItem>
                                            <NomenclatureListPreviewItem
                                                nomenclature={nomenclature}
                                                range={bookingRange.diff}
                                            />
                                        </StyledDescriptionItem>
                                        <StyledDescriptionPrice
                                            isHideListPadding
                                        >
                                            <NomenclatureListPreviewPrice
                                                nomenclature={nomenclature}
                                            />
                                        </StyledDescriptionPrice>
                                    </StyledFooterRow>
                                ))}
                            </>
                        )}
                    </List>
                    <StyledFooterRow>
                        <StyledDescriptionItem>
                            Скидка клиента:
                        </StyledDescriptionItem>
                        <StyledDescriptionPrice>
                            {counterpartyDiscount}%
                        </StyledDescriptionPrice>
                    </StyledFooterRow>
                </StyledFooterColumn>
            </StyledItemsContainer>
        </StyledFooterContainer>
    )
}
