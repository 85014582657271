import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    CounterpartyType,
    useCounterpartyAsyncAutocompleteOptions,
} from 'entities/counterparties/model'
import { useNumberMask } from 'shared/utils/useNumberMask'
import { selectBookingCounterpartyId } from '../slices'

const CounterpartyOptionName = {
    phone: 'phone',
    name: 'name',
    inn: 'inn',
    email: 'email',
    fio: 'contacts'
} as const

type CounterpartyOption = {
    [CounterpartyOptionName.phone]: string
    [CounterpartyOptionName.name]: string
    [CounterpartyOptionName.inn]: string
    [CounterpartyOptionName.email]: string
    [CounterpartyOptionName.fio]: string
}

const initValue = {
    [CounterpartyOptionName.phone]: '',
    [CounterpartyOptionName.name]: '',
    [CounterpartyOptionName.inn]: '',
    [CounterpartyOptionName.email]: '',
    [CounterpartyOptionName.fio]: '',
} as const

export type CounterpartyOptionNameType = (typeof CounterpartyOptionName)[keyof typeof CounterpartyOptionName]

const countryCode = '+7'
const phoneMask = `${countryCode}-000-000-00-00` as const

export const useCounterpartiesOptions = (isCompany?: boolean) => {
    const counterpartyId = useSelector(selectBookingCounterpartyId)
    const { onInput, onKeyDown } = useNumberMask(phoneMask, countryCode)

    const [optionInputValue, setOptionInputValue] =
        useState<CounterpartyOption>(initValue)

    const onChangeCounterpartyOption = useCallback(
        (name: CounterpartyOptionNameType) => (value: string) => {
            setOptionInputValue(prev => ({
                ...prev,
                [name]: value,
            }))
        },
        [],
    )

    const searchString = useMemo(
        () =>
            optionInputValue.phone?.length
                ? optionInputValue.phone
                : optionInputValue.name?.length
                  ? optionInputValue.name
                  : optionInputValue.inn?.length
                    ? optionInputValue.inn
                    : undefined,
        [optionInputValue],
    )

    const { counterparties, fetchNextPage, isFetching } =
        useCounterpartyAsyncAutocompleteOptions({
            search_param: searchString,
            type_filter: isCompany
                ? CounterpartyType.Company
                : CounterpartyType.Person,
        })

    const counterpartiesOptions = useMemo(
        () =>
            counterparties?.length
                ? {
                      [CounterpartyOptionName.phone]: counterparties.map(
                          counterparty => ({
                              value: counterparty.id.toString(),
                              label: counterparty.phone,
                          }),
                      ),
                      [CounterpartyOptionName.name]: counterparties.map(
                          counterparty => ({
                              value: counterparty.id.toString(),
                              label:
                                  counterparty.additionalData.documents
                                      ?.organizationName ?? '',
                          }),
                      ),
                      [CounterpartyOptionName.fio]: counterparties.map(
                        counterparty => ({
                            value: counterparty.id.toString(),
                            label:[
                                counterparty?.contact?.surname,
                                counterparty?.contact?.firstName,
                                counterparty?.contact?.patronymic,
                            ].join(' ')
                        }),
                    ),
                      [CounterpartyOptionName.inn]: counterparties.map(
                          counterparty => ({
                              value: counterparty.id.toString(),
                              label:
                                  counterparty.additionalData.documents?.inn ??
                                  '',
                          }),
                      ),
                      [CounterpartyOptionName.email]: counterparties.map(
                        counterparty => ({
                            value: counterparty.id.toString(),
                            label: counterparty.email ?? '',
                        }),
                    ),
                  }
                : {
                      [CounterpartyOptionName.phone]: [],
                      [CounterpartyOptionName.name]: [],
                      [CounterpartyOptionName.inn]: [],
                      [CounterpartyOptionName.email]: [],
                      [CounterpartyOptionName.fio]: [],
                  },
        [counterparties],
    )

    const currentCounterpartyOption = useMemo(
        () => ({
            [CounterpartyOptionName.phone]: counterpartiesOptions.phone?.find(
                option => option.value === counterpartyId?.toString(),
            ),
            [CounterpartyOptionName.name]: counterpartiesOptions.name?.find(
                option => option.value === counterpartyId?.toString(),
            ),
            [CounterpartyOptionName.inn]: counterpartiesOptions.inn?.find(
                option => option.value === counterpartyId?.toString(),
            ),
            [CounterpartyOptionName.email]: counterpartiesOptions.email?.find(
                option => option.value === counterpartyId?.toString(),
            ),
            [CounterpartyOptionName.fio]: counterpartiesOptions.contacts?.find(
                option => option.value === counterpartyId?.toString(),
            ),
        }),
        [counterpartiesOptions, counterpartyId],
    )

    const currentCounterpartyObject = useMemo(
        () =>
            counterparties?.find(
                counterparty =>
                    counterparty.id.toString() === counterpartyId?.toString(),
            ),
        [counterparties, counterpartyId],
    )

    const currentCounterparty = useMemo(
        () => ({
            email: currentCounterpartyObject?.email,
            name: [
                currentCounterpartyObject?.contact?.surname,
                currentCounterpartyObject?.contact?.firstName,
                currentCounterpartyObject?.contact?.patronymic,
            ].join(' '),
            contactEmail: currentCounterpartyObject?.contact?.email,
            contactPhone: currentCounterpartyObject?.contact?.phone,
            organizationName:
                currentCounterpartyObject?.additionalData?.documents
                    ?.organizationName,
            inn: currentCounterpartyObject?.additionalData?.documents?.inn,
        }),
        [currentCounterpartyObject],
    )

    const onChangeNumber = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('phone')

            if (value.length < (optionInputValue.phone?.length ?? 0)) {
                changeCallback(onKeyDown(value))
                return
            }
            changeCallback(onInput(value))
        },
        [optionInputValue, onChangeCounterpartyOption],
    )

    const onChangeName = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('name')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    const onChangeInn = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('inn')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    const onChangeEmail = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('email')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    const onChangeContactName = useCallback(
        (value: string) => {
            const changeCallback = onChangeCounterpartyOption('name')
            changeCallback(value)
        },
        [onChangeCounterpartyOption],
    )

    return {
        optionInputValue,
        currentCounterpartyOption,
        counterpartiesOptions,
        currentCounterparty,
        fetchNextPage,
        isFetching,
        onChangeNumber,
        onChangeName,
        onChangeInn,
        onChangeEmail,
        onChangeContactName
    }
}
